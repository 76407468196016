export const SET_TEST_CONFIGS = 'SET_TEST_CONFIGS';
export const SET_CONFIG = 'SET_CONFIG';

// merge the provided config with the existing config
export function setTestConfigs(override) {
  return {
    type: SET_TEST_CONFIGS,
    payload: override
  };
}

// replace the entire config
export function setConfig(config) {
  return {
    type: SET_CONFIG,
    payload: config
  };
}
