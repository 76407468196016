import {
  // TODO: remove "_V8" actions when we fully transition to V9 search
  RECEIVE_PROFILE_V8,
  RECEIVE_PROFILE
} from './actions';

import { CLEAR_DATA } from '../actions';

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V8 profile reducer functions (we are deprecating this)
// ------------------------------------------------------------------
// ------------------------------------------------------------------

function getProfileId_V8(provider) {
  const profile = provider.profile || {};
  return profile.id;
}

function getProfileObject_V8(provider) {
  const profileId = getProfileId_V8(provider);
  return { [profileId]: provider };
}

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V9 profile reducer functions
// ------------------------------------------------------------------
// ------------------------------------------------------------------

function getProfileId(responseData) {
  const profileId = responseData.provider?.id;
  return profileId;
}

function getProfileObject(responseData) {
  const profileId = getProfileId(responseData);
  return {
    [profileId]: {
      ...responseData,
      provider: {
        ...responseData.provider,
        has_provider_availability: !!responseData.availability?.slots?.length
      }
    }
  };
}

export default function profileReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_PROFILE_V8:
      return Object.assign({}, state, getProfileObject_V8(action.payload));
    case RECEIVE_PROFILE:
      return Object.assign({}, state, getProfileObject(action.payload));
    case CLEAR_DATA:
      return {};
    default:
      return state;
  }
}
