import { CERNER_BCS_TOKEN_QUERY_PARAM } from './cerner';
import {
  PMC_DBW_ENABLED_PROPERTY,
  PMAC_DBW_ENABLED_PROPERTY
} from '../direct-booking/constants';

export const VALID_PATIENT_RELS = ['new', 'established'];

export const SCHEDULING_VISIBILITY_AGENT = 'agent:direct_book';
export const SCHEDULING_VISIBILITY_CONSUMER = 'consumer:direct_book';

// avail tiles constants start

export const MAX_AVAILABILITIES_TO_DISPLAY = 5;
export const READ_ONLY_MAX_AVAILABILITIES_TO_DISPLAY = 6;

export const PROFILE_MAX_AVAILABILITIES_TO_DISPLAY = 6;
export const PROFILE_READ_ONLY_MAX_AVAILABILITIES_TO_DISPLAY = 6;

export const SLOTS_LOADING_STATUS = '@slots/loading';
export const SLOTS_SUCCESS_STATUS = '@slots/success';
export const SLOTS_ERROR_STATUS = '@slots/error';

export const V8_PATIENT_REL_FILTER_KEY =
  'appointment_ehr_purposes.patient_relationship';
export const V8_PURPOSE_FILTER_KEY = 'appointment_ehr_purposes.name';
export const V8_VISIBILITIES_FILTER_BASE =
  'appointment_ehr_purposes.ehr_data.visibilities';
export const V8_DIRECT_BOOK_CAPABLE_FILTER = 'direct_book_capable:T';

// see the below thread for more context on v9 visibility filters
// https://kyruus.slack.com/archives/C022FQWHWJ1/p1658425446062959
export const V9_PATIENT_REL_FILTER_KEY =
  'provider.appointment_ehr_purposes.patient_relationship';
export const V9_PURPOSE_FILTER_KEY = 'provider.appointment_ehr_purposes.name';
export const V9_VISIT_METHOD_FILTER_KEY =
  'provider.appointment_ehr_purposes.visit_method';

export const V9_PATIENT_RELATIONSHIP_FIELD = 'patient_relationship';
export const V9_PURPOSE_FIELD = 'purpose';
export const V9_VISIT_METHOD_FIELD = 'visit_method';
// TODO: add visit_method once supported
export const V9_APPOINTMENT_FILTER_TO_QUERY_FIELD_MAP = {
  [V9_PATIENT_REL_FILTER_KEY]: V9_PATIENT_RELATIONSHIP_FIELD,
  [V9_PURPOSE_FILTER_KEY]: V9_PURPOSE_FIELD,
  [V9_VISIT_METHOD_FILTER_KEY]: V9_VISIT_METHOD_FIELD
};

export const V9_DIRECT_BOOK_CAPABLE_FILTER =
  'provider.direct_book_capable:true';

export const V9_DIRECT_BOOK_ENABLED_FILTER_AGENT = `provider.${PMAC_DBW_ENABLED_PROPERTY}:true`;
export const V9_DIRECT_BOOK_ENABLED_FILTER_CONSUMER = `provider.${PMC_DBW_ENABLED_PROPERTY}:true`;

// List of `filter`s that are not to render along side the `facets`
export const FACET_DISPLAY_BLACKLIST = [
  V9_PATIENT_REL_FILTER_KEY,
  V9_PURPOSE_FILTER_KEY,
  V9_VISIT_METHOD_FILTER_KEY,
  `provider.${PMAC_DBW_ENABLED_PROPERTY}`,
  `provider.${PMC_DBW_ENABLED_PROPERTY}`
];

export const V9_HAS_AVAILABILITY_PARAM_KEY = 'has_availability';

export const NEW_PATIENT = 'new';
export const ESTABLISHED_PATIENT = 'established';

export const PROVIDER_ID_FIELD_NAME = 'provider.id';
export const VALID_SLOTS_V9_FILTERS_FACETS = [
  'date',
  'ehr_location_id',
  'end',
  'location_id',
  'patient_relationship',
  'provider_id',
  'purpose',
  'source_system',
  'start',
  'visibilities.agent',
  'visibilities.consumer'
];

// avail tiles constants end

export const ENTITY_TYPE = 'entity_type';
export const LOCATION_ENTITY_TYPE = 'location';

// Redirect constants
export const REDIRECT_ERROR = {
  // Failing to redirect the user to their previous spot in the direct book flow following a third-party login attempt due to an invalid deep-linking URL
  DIRECT_BOOK_DEEPLINKING: 'redirect_error.direct_book.deeplinking'
};
// Redirect constants end

/**Key used by the GX API to apply searched clinical keywords to any GX "clinical validation" question. */
export const CLINICAL_KEYWORD = 'clinical_keyword';
/**Search v8 typeahead category for clinical keywords. */
export const CLINICAL_EXPERIENCE = 'clinical_experience';
/**Search v9 typeahead category for clinical keywords. */
export const CLINICAL_KEYWORDS = 'clinical_keywords';

// query string params that can populate typeaheadTerms in the search widget machine
// during initialization. used to prepopulate the text input on page load
// TODO is this complete AND may need to be update when we migrate to v9
export const TYPEAHEAD_SEARCH_PARAMETERS = [
  'alias_term',
  'clinical_combined',
  CLINICAL_EXPERIENCE,
  CLINICAL_KEYWORDS,
  'conditions_symptoms',
  'location_name',
  'name',
  'network_affiliation',
  'practice_group',
  'practice_location',
  'primary_care_condition',
  'primary_care',
  'provider_name',
  'specialties',
  'specialty_all',
  'specialty_strict',
  'specialty_synonym',
  'specialty',
  'treatments_procedures',
  'unified'
];

// Search V9 Distance Expansion Strings (KENG-34819)
export const SEARCH_V9_DISTANCE_EXPANSION_STRINGS = {
  DISTANCE_FILTER_REMOVED: 'distance filter removed',
  NOT_USED: 'not used'
};

// User location constants start
// localstorage
export const USER_LOCATION_KEY = 'location';
export const USER_LOCATION_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours converted to miliseconds
// positionOptions for Geolocation API
export const GEOLOCATION_CACHE_MAXIMUM_AGE = 24 * 60 * 60 * 1000;
export const GEOLOCATION_TIMEOUT = 5 * 1000;
// errors
export const GENERIC_GEOLOCATION_ERROR = {
  code: 0,
  message: 'Geolocation not supported'
};
// User location constants end

// 'sticky' query params to persist across page views and searches
export const STICKY_QUERY_PARAMS = [CERNER_BCS_TOKEN_QUERY_PARAM];

// strip out query params we don't want to pass to search service.
// really this should be a whitelist instead of a blacklist, but we can wait
// until v9 migration -- and proper ts types -- to set that up
export const V9_QUERY_PARAMS_BLACKLIST = [CERNER_BCS_TOKEN_QUERY_PARAM];

export const PRIMARY_MLOC_FACET = 'locations.primary_marketable_location_id';
export const V9_RESPONSE_CONTEXT_PARAMS_BLACKLIST = [
  'facet',
  'per_page',
  'context',
  'tracking_token',
  'search_token',
  'search_id_token',
  'user_id',
  'user_token',
  'components'
];

export const LOCATION_FACET_DEFAULT_SORT_ORDER = 'distance';
export const LOCATION_FACET_DEFAULT_DISTANCE = '25';
