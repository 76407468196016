import {
  RECEIVE_SEARCH_V9,
  RECEIVE_SEARCH_ERROR,
  TOGGLE_MOBILE_FACETS
} from './actions';

import { CLEAR_DATA } from '../actions';

export const DEFAULT_STATE = {
  providerIds: [],
  totalProviders: null,
  totalPages: null,
  facets: [],
  searchSummary: {},
  sortOptions: []
};

function getSearchPageState(payload) {
  const providerIds = (payload.providers || []).map(
    (provider) => provider.provider.id || provider.profile.id
  );
  const nextState = { ...payload };
  delete nextState.providers;
  nextState.providerIds = providerIds;
  return nextState;
}

export default function searchV9Reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case RECEIVE_SEARCH_V9:
      return {
        ...state,
        ...getSearchPageState(action.payload)
      };
    case RECEIVE_SEARCH_ERROR:
      return Object.assign({}, state, DEFAULT_STATE);
    case TOGGLE_MOBILE_FACETS:
      return Object.assign({}, state, {
        showMobileFacets: !state.showMobileFacets
      });
    case CLEAR_DATA:
      return Object.assign({}, DEFAULT_STATE);
    default:
      return state;
  }
}
