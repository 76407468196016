import { NEW_PATIENT, ESTABLISHED_PATIENT } from '../../utils/constants';

export async function unwrapResponse(fetchPromise) {
  const response = await fetchPromise;

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const json = await response.json();

  return json;
}

export function convertFacetsToSlots(facetData, apptInfo) {
  const groupedSlots = {};
  for (const { terms: locTerms, value: providerId } of facetData[0].terms) {
    groupedSlots[providerId] = {
      apptInfo,
      slots: []
    };
    for (const { terms: dateTerms, value: location_id } of locTerms) {
      for (const { terms: startTerms, value: date } of dateTerms) {
        for (const { value: start } of startTerms) {
          const slot = {
            date,
            start,
            location_id,
            purpose: apptInfo.purpose,
            patient_relationship: apptInfo.relationship
          };
          groupedSlots[providerId].slots.push(slot);
        }
      }
    }
  }
  return groupedSlots;
}

/**
 * Convert slots facets to purposes by relationship. `purposesSet` maintains
 * functionality for v8
 *
 * @param {} facets
 * @returns {Object} { new: { purposes: [], purposesSet: Set() }, established: { purposes: [], purposesSet: Set() } }
 */
export const convertFacetsToPurposes = (facets) => {
  const purposes = {
    [ESTABLISHED_PATIENT]: { purposes: [], purposesSet: new Set() },
    [NEW_PATIENT]: { purposes: [], purposesSet: new Set() }
  };

  if (facets.length === 0) return purposes;

  return facets[0].terms.reduce((acc, relationship) => {
    const purposes = relationship.terms.reduce(
      (acc, purpose) => {
        acc.purposesSet.add(purpose.value);
        acc.purposes.push({
          name: purpose.value,
          visit_method: purpose.terms[0].value,
          // value is a string, so convert to boolean
          default: purpose.terms[0].terms[0].value === 'true' ? true : false
        });
        return acc;
      },
      { purposes: [], purposesSet: new Set() }
    );

    acc[relationship.value] = purposes;
    return acc;
  }, purposes);
};
