import React, { useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { DirectBookDrawer } from './shared/direct-book-drawer';

import ErrorMessage from './error/error';
import { MODULES, isModuleEnabled } from 'Common/config';

const isValidSearchMatchRoute = (config) => {
  return isModuleEnabled(config, MODULES.SEARCH_MATCH);
};

const isValidDirectBookRoute = (config) => {
  return isModuleEnabled(config, MODULES.DIRECT_BOOK);
};

const isValidProfileRoute = (config) => {
  return isModuleEnabled(config, MODULES.PROFILE);
};

// Appointment cancel is currently always enabled but maybe it won't be in the future
const isValidAppointmentCancelRoute = () => {
  return true;
};

const isValidRedirectRoute = () => {
  return true;
};

// Turns on /location page - Location Profile Page
const isValidLocationRoute = (config) => {
  return isModuleEnabled(config, MODULES.LOCATIONS);
};

// Turns on the /locations page - Map Search Page
const isValidMapSearchRoute = (config) => {
  return isModuleEnabled(config, MODULES.MAP_SEARCH);
};

export const SearchMatchRoute = ({
  component: Component,
  config,
  log,
  searchSDK,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(routeProps) =>
        isValidSearchMatchRoute(config) ? (
          <Component log={log} searchSDK={searchSDK} {...routeProps} />
        ) : (
          <ErrorMessage />
        )
      }
    />
  );
};

export const ProfileRoute = ({
  component: Component,
  config,
  log,
  searchSDK,
  ...props
}) => {
  return (
    <Route
      {...props}
      render={(routeProps) =>
        isValidProfileRoute(config) ? (
          <Component log={log} searchSDK={searchSDK} {...routeProps} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export const DirectBookRoute = ({
  component: Component,
  config,
  log,
  searchSDK,
  ...props
}) => (
  <Route
    {...props}
    render={(routeProps) => {
      return isValidDirectBookRoute(config) ? (
        <Component log={log} searchSDK={searchSDK} {...routeProps} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

// Route for rendering DBW within a drawer.
// Relies on react-router v4 modal routing.
// See https://v5.reactrouter.com/web/example/modal-gallery for more info.
export const DirectBookDrawerRoute = ({
  component: Component,
  config,
  log,
  searchSDK,
  provider,
  ...props
}) => {
  const drawerBodyRef = useRef(null);
  const handleScroll = () => {
    drawerBodyRef.current?.scrollTo(0, 0);
  };
  return (
    <DirectBookDrawer
      ref={drawerBodyRef}
      provider={provider}
      config={config}
      log={log}
    >
      <Route
        {...props}
        render={(routeProps) => {
          return isValidDirectBookRoute(config) ? (
            <Component
              log={log}
              searchSDK={searchSDK}
              onScroll={handleScroll}
              {...routeProps}
            />
          ) : (
            <Redirect to="/" />
          );
        }}
      />
    </DirectBookDrawer>
  );
};

export const AppointmentCancelRoute = ({
  component: Component,
  config,
  log,
  searchSDK,
  ...props
}) => (
  <Route
    {...props}
    render={(routeProps) => {
      return isValidAppointmentCancelRoute(config) ? (
        <Component log={log} searchSDK={searchSDK} {...routeProps} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

export const RedirectRoute = ({
  component: Component,
  config,
  log,
  ...props
}) => (
  <Route
    {...props}
    render={(routeProps) => {
      return isValidRedirectRoute(config) ? (
        <Component log={log} {...routeProps} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

export const LocationRoute = ({
  component: Component,
  config,
  log,
  ...props
}) => (
  <Route
    {...props}
    render={(routeProps) => {
      return isValidLocationRoute(config) ? (
        <Component log={log} {...routeProps} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);

export const MapSearchRoute = ({
  component: Component,
  config,
  log,
  ...props
}) => (
  <Route
    {...props}
    render={(routeProps) => {
      return isValidMapSearchRoute(config) ? (
        <Component log={log} {...routeProps} />
      ) : (
        <Redirect to="/" />
      );
    }}
  />
);
