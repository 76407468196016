import { v4 as uuidv4 } from 'uuid';
import {
  COOKIE_NAMES,
  QUERY_PARAMS,
  METADATA_FIELDS,
  SEARCH_SHUFFLE_TOKEN_FOR_BOTS
} from './constants';
import { isSearchIdTokenValid, isUuidValid } from './validation';
import type { TokenDefinition } from './types';
import { checkIfBot } from '../../../helpers/get-user-agent-info';
import type { Request } from 'Common/types/express';

// expiration unit is in `minutes`
const ONE_DAY = 60 * 24;
// As of 2022, 400 days is the longest expiration time for a cookie, and
// set cookies shouldn't be set to expire longer than that.
// Use 399 here to avoid any TZ or rounding issues with the 400 day limit.
const THREE_HUNDRED_AND_NINETY_NINE_DAYS = ONE_DAY * 399;

export function generateUuid() {
  return uuidv4();
}

export function generateShuffleToken({ req }: { req?: Request }) {
  /**
   * use hardcoded shuffle token for bots. This should be safe as a
   * server-side only check, because the token will be saved on the first
   * request, so by the time the client sees the user for the first time
   * the token will always be set already
   */
  if (req && checkIfBot(req)) {
    return SEARCH_SHUFFLE_TOKEN_FOR_BOTS;
  }
  return generateUuid();
}

/**
 * Meant for tying all events in a single user visit together,
 * but not for long-term tracking
 */
export const CONSUMER_TRACKING_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.CONSUMER_TRACKING_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.CONSUMER_TRACKING_TOKEN,
  generateByDefault: true,
  validateFunc: isUuidValid,
  generateFunc: generateUuid,
  cookieExpiration: ONE_DAY
};

/**
 * Ties api requests during the same SSR render and api calls together,
 * Also carries over to client side tracking directly on the rendered page
 * and pages navigated to via history push in the client app.
 *
 * Kind of broken for its original purpose, since it isn't cookied and is lost
 * during non-push-state transitions from the SERP to the profile page. Should be
 * considered deprecated in favor of SEARCH_ID_TOKEN
 *
 * ⚠️⚠️⚠️ NOT TO BE CONFUSED WITH SEARCH_ID TOKEN ⚠️⚠️⚠️
 */
export const SEARCH_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_TOKEN,
  checkQueryTimestamp: false,
  cookieName: null, // search token does not get added to nor read from cookies
  generateByDefault: true,
  generateFunc: generateUuid,
  validateFunc: isUuidValid,
  cookieExpiration: ONE_DAY
};

/**
 * Meant as a long-lived user tracking token, for tracking
 * repeat users and has a long expiration time when cookied
 */
export const USER_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.USER_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.USER_TOKEN,
  generateByDefault: true,
  validateFunc: isUuidValid,
  generateFunc: generateUuid,
  cookieExpiration: THREE_HUNDRED_AND_NINETY_NINE_DAYS
};

/**
 * Stores the shuffle token value passed to the search api, to make sure the order of
 * providers is stable between page reloads and pagination between pages
 */
export const SEARCH_SHUFFLE_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_SHUFFLE_TOKEN,
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.SEARCH_SHUFFLE_TOKEN,
  generateByDefault: true,
  generateFunc: generateShuffleToken,
  validateFunc: isUuidValid,
  cookieExpiration: ONE_DAY
};

/**
 * The search ID token is used to track a user's search session.
 * It is regenerated when a user creates a new search via either search term
 * or location change, and then passed to subsequent tracking events
 *
 * If the user hasn't made a search during their session, the value will be `null`
 * ⚠️⚠️⚠️ NOT TO BE CONFUSED WITH SEARCH TOKEN ⚠️⚠️⚠️
 */
export const SEARCH_ID_TOKEN: TokenDefinition = {
  queryParamName: QUERY_PARAMS.SEARCH_ID_TOKEN, // as in kyruus search id, because `sid` is already used for the application's session id cookie
  checkQueryTimestamp: true,
  cookieName: COOKIE_NAMES.SEARCH_ID_TOKEN,
  generateByDefault: false,
  validateFunc: isSearchIdTokenValid,
  cookieExpiration: ONE_DAY
};

/**
 * It's not a tracking token, but instead a token used to track either the logged in
 * or anonymous user associated to the current actor
 */
export const USER_ID: TokenDefinition = {
  checkQueryTimestamp: false,
  metadataField: METADATA_FIELDS.USER_ID,
  generateByDefault: false
};
