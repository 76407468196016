import { SET_TOKENS } from './actions';

export default function tokensReducer(state = {}, action) {
  if (action.type === SET_TOKENS) {
    return {
      ...state,
      ...action.payload
    };
  }
  return state;
}
