export const QUERY_PARAMS = {
  SEARCH_ID_TOKEN: 'srch',
  SEARCH_SHUFFLE_TOKEN: 'sst',
  USER_TOKEN: 'ut',
  SEARCH_TOKEN: 'st',
  CONSUMER_TRACKING_TOKEN: 'tt'
};

export const COOKIE_NAMES = {
  // not to be confused with SEARCH_TOKEN which does not have a cookie
  SEARCH_ID_TOKEN: 'search_id_token',
  SEARCH_SHUFFLE_TOKEN: 'search_shuffle_token',
  USER_TOKEN: 'consumer_user_token',
  CONSUMER_TRACKING_TOKEN: 'consumer_tracking_token'
};

export const METADATA_FIELDS = {
  USER_ID: 'userId'
};

export const METADATA_STORE_NAME = '__kyruus_logging_metadata';

export const TIMESTAMP_QUERY_PARAM_NAME = 'timestamp';
export const TIMESTAMP_ALLOWED_FUTURE_MINUTES = 30;
export const TIMESTAMP_ALLOWED_PAST_MINUTES = 30;

// always use the same shuffle token for bots, so that they get consistent results when paginating our results pages.
// send hardcoded value instead of cookie in case bots are not storing cookies between page loads.
// The value itself doesn't matter as long as it's the same between page views, and the value is not used for tracking.
export const SEARCH_SHUFFLE_TOKEN_FOR_BOTS =
  '060e867d-c1aa-404c-8b46-2a9d33dbf6d5';
