import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import Drawer from '@kyruus/drawer';
import { withRouter } from 'react-router-dom';
import {
  getProviderDisplayName,
  getProviderImageUrl
} from '@kyruus/provider-components';
import Cancel from '@kyruus/react-ikons/Cancel';
import Button from '@kyruus/button';
import { DBW_DRAWER_MAX_WIDTH } from '../../direct-booking/constants';
import { DIRECTBOOK_DRAWER_CLOSED } from '../../direct-booking/tracking';
import { getReasonForDirectBookDrawerClosure } from '../../direct-booking/utils';

// Apply a width and max width to keep the drawer from changing size too wildly
const DBDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    max-width: ${DBW_DRAWER_MAX_WIDTH}px;
    width: 100%;
  }
`;

const DBDrawerHeader = styled('header')`
  padding: ${fromTheme('spacing_small')};
  position: sticky;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${fromTheme('spacing_medium')};
  @media print {
    display: none;
    @page {
      margin: 0;
    }
  }
`;

const ProviderInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: ${fromTheme('spacing_xsmall')};
  & > span {
    font-size: ${fromTheme('font_size')};
    color: ${fromTheme('color_text_secondary')};
  }
  & > span:first-of-type {
    font-size: ${fromTheme('font_size_heading_5')};
    color: ${fromTheme('color_text')};
  }
`;

const DBDrawerCloseButton = styled(Button)`
  padding: ${fromTheme('spacing_small')};
  flex: 0;
  &:focus {
    background-color: inherit;
    border-color: ${fromTheme('color_border')};
  }
  &:hover {
    background-color: inherit;
    border-color: ${fromTheme('color_border')};
    color: ${fromTheme('color_item_hover')};
  }
  @media print {
    display: none;
  }
`;

const DBDrawerCloseButtonWithDBHeader = styled(DBDrawerCloseButton)`
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 1000;
  margin: ${fromTheme('spacing_small')} ${fromTheme('spacing_medium')};
`;
// Ensure that the drawer content is scrollable (vertically)
const DBDrawerContent = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  & .DirectBook {
    & .MobileHeaderContent {
      padding: ${fromTheme('spacing_xsmall')};
    }
    & .BookButtonContainer {
      max-width: ${DBW_DRAWER_MAX_WIDTH}px;
      left: inherit;
      right: 0px;
    }
    &
      .ProviderLocationsBody
      > ul.ProviderLocationsList
      > li
      > .BookableLocation {
      flex-flow: row-reverse;
      align-items: center;
      & > div {
        width: 100%;
      }
    }
    & li.GroupedAppt.GroupedAppt {
      flex: 0 1 100px;
      & > button {
        padding: ${fromTheme('spacing_small')};
      }
    }
  }
`;

const PROVIDER_IMAGE_MAX_HEIGHT = '40px';
const PROVIDER_IMAGE_MAX_WIDTH = '100%';

const ProviderImageContainer = styled('img')`
  flex: 0;
  grid-area: image;
  display: block;
  max-width: ${PROVIDER_IMAGE_MAX_WIDTH};
  max-height: ${PROVIDER_IMAGE_MAX_HEIGHT};
  height: auto;
  width: auto;
  border-radius: ${fromTheme('border_radius')};

  @media only screen and (min-width: ${fromTheme('screen_medium')}) {
    max-width: 90px;
  }
`;

const DirectBookDrawerBase = withRouter(
  ({ forwardedRef, provider, config, history, children, log, ...props }) => {
    const closeButtonSize = fromTheme('font_size_heading_3')(props);
    const closeButtonColor = fromTheme('color_text_secondary')(props);
    const name = provider ? getProviderDisplayName(provider) : '';
    const specialty = provider
      ? provider.specialties
          .filter((specialty) => !!specialty.name)
          .map((specialty) => specialty.name)
          .join(', ')
      : '';
    const imageUrl = provider ? getProviderImageUrl(provider) : '';
    const handleClose = (event, reason) => {
      const closureReason = getReasonForDirectBookDrawerClosure(reason);
      log(DIRECTBOOK_DRAWER_CLOSED, { method: closureReason });
      history.goBack();
    };
    return (
      <DBDrawer
        {...props}
        open={provider != null}
        variant="temporary"
        anchor="right"
        ModalProps={{
          keepMounted: true
        }}
        onClose={handleClose}
      >
        {provider != null && (
          <>
            {!(
              config?.darkship_feature_flags &&
              config?.darkship_feature_flags?.use_direct_book_v2
            ) ? (
              <DBDrawerHeader data-testid="direct-book-drawer-header">
                <ProviderImageContainer
                  alt={name}
                  id={`direct-book-drawer-image-${name}`}
                  src={imageUrl}
                  itemProp="image"
                />
                <ProviderInfoContainer>
                  <span>{name}</span>
                  <span>{specialty}</span>
                </ProviderInfoContainer>
                <DBDrawerCloseButton
                  data-testid="db_drawer_close_button"
                  mode="flat"
                  onClick={handleClose}
                >
                  <Cancel size={closeButtonSize} color={closeButtonColor} />
                </DBDrawerCloseButton>
              </DBDrawerHeader>
            ) : (
              <DBDrawerCloseButtonWithDBHeader
                aria-label="Close scheduling drawer"
                data-testid="db_drawer_close_button_with_db_header"
                mode="flat"
                onClick={handleClose}
              >
                <Cancel
                  aria-hidden={true}
                  size={closeButtonSize}
                  color={closeButtonColor}
                />
              </DBDrawerCloseButtonWithDBHeader>
            )}
            <DBDrawerContent ref={forwardedRef}>{children}</DBDrawerContent>
          </>
        )}
      </DBDrawer>
    );
  }
);

export const DirectBookDrawer = forwardRef((props, ref) => (
  <DirectBookDrawerBase {...props} forwardedRef={ref} />
));

DirectBookDrawer.displayName = 'DirectBookDrawer';
