import _get from 'lodash/get';

import { MODULES, isModuleEnabled } from 'Common/config';
import {
  TYPEAHEAD_SEARCH_PARAMETERS,
  SEARCH_V9_DISTANCE_EXPANSION_STRINGS,
  SCHEDULING_VISIBILITY_AGENT,
  SCHEDULING_VISIBILITY_CONSUMER,
  V9_DIRECT_BOOK_ENABLED_FILTER_AGENT,
  V9_DIRECT_BOOK_ENABLED_FILTER_CONSUMER
} from './constants';
import { DISTANCE_ANY } from '../search-v9/facet-panel/facet-list/location-facet';

// eslint-disable-next-line no-unused-vars
const { DISTANCE_FILTER_REMOVED, NOT_USED } =
  SEARCH_V9_DISTANCE_EXPANSION_STRINGS;

/**
 * Get sort options for the sort/filter component based on customer config and search params
 * @param {Object} customerConfig
 * @param {Object} searchParams Params sent to search api to retrieve search results
 * @returns
 */
export function getSortOptions(customerConfig, searchParams) {
  const sortValues = {
    best_match: (customerConfig.search_widget || {}).sort_order || 'relevance',
    distance:
      (customerConfig.location_facet || {}).custom_sort_order || 'distance',
    availability: 'availability_density_best',
    availability_soonest: 'availability_soonest',
    alphabetical: 'name',
    reverse_alphabetical: '-name'
  };
  let sortOptions = customerConfig.sort_options || [
    'best_match',
    'distance',
    'alphabetical',
    'reverse_alphabetical'
  ];
  if (!('location' in searchParams)) {
    // remove distance sort when not searching based on location
    sortOptions = sortOptions.filter((option) => option !== 'distance');
  }
  return sortOptions.map((sortOption) => ({
    descriptor_id: sortOption.replace('_', ''),
    value: sortValues[sortOption]
  }));
}

export const getDefaultCustomerSort = (customerConfig) => {
  const customerSortOptions = getSortOptions(customerConfig, {});
  const firstConfiguredOption = customerSortOptions[0];

  return firstConfiguredOption.value;
};

/**
 * Returns the search summary object.
 * @param query {string} current query string in the URL (must include the ?)
 * @returns object
 */
export const getSearchSummary = (searchParams, searchV9DistanceExpansion) => {
  /**
   * possible search type expected from search widget query
   * taken reference from search_service.py - get_search_summary
   * and https://support.kyruus.com/hc/en-us/articles/360057514692-About-Searching-for-Providers
   */
  const searchQueries = [
    'conditions_symptoms',
    'clinical_combined',
    'clinical_experience',
    'clinical_keywords',
    'location_name',
    'name',
    'network_affiliation',
    'practice_group',
    'practice_location',
    'provider_name',
    'primary_care',
    'primary_care_condition',
    'specialty',
    'specialties',
    'specialty_all',
    'specialty_strict',
    'specialty_synonym',
    'treatments_procedures',
    'unified'
  ];

  const aliasTerm = 'alias_term';

  const params = Object.keys(searchParams).find((item) =>
    searchQueries.includes(item)
  );

  let searchDescription;

  if (searchParams[aliasTerm]) {
    searchDescription = searchParams[aliasTerm];
  } else {
    searchDescription = searchParams[params];
  }

  return {
    ...searchParams,
    ...{
      search_type: params,
      search_description: searchDescription,
      search_query: searchDescription
    },
    ...getSearchSummaryDistance(
      searchParams.distance,
      searchV9DistanceExpansion
    )
  };
};

/**
 * @param originalInputDistance {number} The original user-inputted distance
 * @param searchV9DistanceExpansion {string | number} Search V9 API's string description (e.g. DISTANCE_FILTER_REMOVED, NOT_USED)
 * or an expanded search radius in miles (e.g. 5, 8, 10, 25, 50, 100) of the API's auto distance expansion feature.
 * If a given search containing a distance param has no results, Search V9 auto expands the search's distance in increments and
 * eventually removes the distance param altogether (e.g. DISTANCE_FILTER_REMOVED) if no results are found within a search distance of 100 miles.
 * NOTE: A value of NOT_USED is returned if no distance filter was supplied to the API or if results are found for a provided distance.
 * @returns object
 */
const getSearchSummaryDistance = (
  originalInputDistance,
  searchV9DistanceExpansion
) => {
  if (typeof originalInputDistance === 'undefined') {
    return {};
  }

  /** Boolean on whether Search V9 expanded the search distance. */
  const isDistanceExpanded =
    typeof searchV9DistanceExpansion === 'number' ||
    searchV9DistanceExpansion === DISTANCE_FILTER_REMOVED;

  /** Converts a Search V9 removed distance filter to DISTANCE_ANY or converts a number type distance expansion value to a string */
  let sanitizedDistanceExpansion;
  if (searchV9DistanceExpansion === DISTANCE_FILTER_REMOVED) {
    sanitizedDistanceExpansion = DISTANCE_ANY;
  } else if (typeof searchV9DistanceExpansion === 'number') {
    sanitizedDistanceExpansion = searchV9DistanceExpansion.toString();
  }

  return {
    distance: isDistanceExpanded
      ? sanitizedDistanceExpansion
      : originalInputDistance,
    _original_input_distance: originalInputDistance
  };
};

/**
 * Returns the value of a querystring param in a URL where the param
 * is included in a list of params we match against. For use in pre-
 * populating the search widget text input on page load. E.g.
 *
 * ?unified=heart&sort=relevance%2Cavailability_density_best%2Cname => heart
 *
 * @param queryString string from window.location.search
 * @returns string the value of the matching querystring param
 * @returns null if no match is found
 */
export const findInitialTypeaheadTerms = (queryString) => {
  const qsParams = new URLSearchParams(queryString);

  for (const [param, value] of qsParams.entries()) {
    if (TYPEAHEAD_SEARCH_PARAMETERS.includes(param)) return value;
  }

  return undefined;
};

export const getLocationValue = (queryString) => {
  const qsParams = new URLSearchParams(queryString);

  return (
    qsParams.get('display_location') ?? qsParams.get('location') ?? undefined
  );
};

export const shouldOpenDirectBookSameWindow = (config) =>
  isModuleEnabled(config, MODULES.DIRECT_BOOK);

export const getBookingUrlTarget = (config) => {
  if (shouldOpenDirectBookSameWindow(config)) {
    return '_self';
  }
  return;
};

/**
 * Get visibility based on config (consumer vs. agent)
 * @param {*} config
 * @returns object
 */
export function getVisibility(config = {}) {
  const isVisibilityAgent = isModuleEnabled(config, MODULES.VISIBILITY_AGENT);
  if (isVisibilityAgent) {
    return {
      isVisibilityAgent,
      role: 'agent',
      schedulingVisibility: SCHEDULING_VISIBILITY_AGENT,
      v9DirectBookEnabledFilter: V9_DIRECT_BOOK_ENABLED_FILTER_AGENT
    };
  }
  return {
    isVisibilityAgent,
    role: 'consumer',
    schedulingVisibility: SCHEDULING_VISIBILITY_CONSUMER,
    v9DirectBookEnabledFilter: V9_DIRECT_BOOK_ENABLED_FILTER_CONSUMER
  };
}

/**
 * Returns the values used for setting the initial state of pre-filters on Search Widget page
 * Only "accepting new patients" and "insurance" are supported
 * There is currently no use case for a preset insurance value so it's always an empty string
 * If 'selected' is missing from the accepting new patients config, default to false, otherwise true
 * @param {{search_widget: {pre_search_filters: [{facet: "accepting_new_patients", selected: ["T"]}, {facet: "insurance_accepted"}]}}} customerConfig
 * @returns {{acceptingNewPatients: boolean, selectedInsurance: ''}}
 */
export const getInitialPreFiltersValue = (customerConfig) => {
  const preFilterConfig =
    customerConfig?.search_widget?.pre_search_filters ?? [];
  const acceptingNewPatientsConfig = preFilterConfig.find(
    (filter) => filter.facet === 'accepting_new_patients'
  );
  const selected = _get(acceptingNewPatientsConfig, 'selected', undefined);
  const acceptingNewPatients = Boolean(selected);
  return {
    acceptingNewPatients,
    selectedInsurance: ''
  };
};
