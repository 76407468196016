type BaseCookieParams = {
  httpOnly: boolean;
  secure: boolean;
  sameSite: 'None' | 'Strict' | 'Lax';
  partitioned: boolean;
  path: string;
};

const BASE_COOKIE_PARAMS: BaseCookieParams = {
  httpOnly: false,
  secure: true,
  sameSite: 'None',
  partitioned: true,
  path: '/'
};

export function getBaseCookieParams(): BaseCookieParams {
  return BASE_COOKIE_PARAMS;
}
