import Cookies from 'js-cookie';
import trackyr from '@kyruus/trackyr';
import type { LoggingMetadata } from 'Common/types/logging';
import {
  getCommonTrackingParams,
  METADATA_STORE_NAME
} from 'Common/logging/tokens';

import { CONSUMER_TRACKING_TOKEN, getTokenValue } from 'Common/logging/tokens';

import {
  getExternalTrackingParams,
  isGoogleAnalyticsLoaded,
  trackGaEvent
} from './tracking-utils';

import { shouldTrackToTealium, trackTealiumEvent } from './tealium';

declare global {
  interface Window {
    _admin_logging: any;
    admin: any;
    [METADATA_STORE_NAME]: LoggingMetadata;
    // testcafe functional testing
    '%hammerhead%': boolean;
  }
}

const PRODUCT_NAME = 'Providermatch Client';

/**
 * @typedef {Object} TrackingInitConfig
 * @prop {Object} initMetadata
 * @prop {Object} eventMetadata
 */

export function getInitAndEventMetadata({
  loggingMetadata
}: {
  loggingMetadata: LoggingMetadata;
}): { eventMetadata: any; initMetadata: any } {
  const initMetadata = {
    product_name: PRODUCT_NAME,
    tracking_token: getTokenValue({ token: CONSUMER_TRACKING_TOKEN }),
    deployment: loggingMetadata.deployment || '',
    customer_code: loggingMetadata.customerCode || '',
    user_id: loggingMetadata.userId || ''
  };

  const eventMetadata = {
    customer_id: loggingMetadata.customerCode || '',
    actor: loggingMetadata.actor || '',
    distinct_id: loggingMetadata.distinctId || '',
    email: loggingMetadata.email || '',
    user_type: loggingMetadata.userType || ''
  };

  return { initMetadata, eventMetadata };
}

/**
 * initializes `trackyr` and returns a `log` function to be used for tracking
 * //
 * @param {{ loggingMetadata: TrackingInitConfig }} init_metadata
 * @returns {Function}
 **/
export const initializeLogging = ({
  loggingMetadata,
  customerConfig
}: {
  loggingMetadata: LoggingMetadata;
  customerConfig: any;
}) => {
  // set global logging metadata, for getTokenValue to be able to access
  // certain special page-wide tokens in a read only fashion.
  window[METADATA_STORE_NAME] = Object.freeze(
    loggingMetadata
  ) as LoggingMetadata;

  const { initMetadata, eventMetadata } = getInitAndEventMetadata({
    loggingMetadata
  });

  // collect external tracking params like those that google appends to tracked links
  const externalTrackingParams = getExternalTrackingParams();

  const trackyrInitMetadata = {
    ...initMetadata,
    ...externalTrackingParams,
    referrer: window.document.referrer || 'UNKNOWN'
  };

  trackyr.init(trackyrInitMetadata);

  const log = (eventName: string, eventData = {}) => {
    try {
      // token values are re-calculated on every tracking call because they could
      // change during the session after the initial pageload
      const commonTrackingParams = getCommonTrackingParams();

      // trackyr/muus
      const trackyrData = {
        eventName: eventName,
        eventData: {
          ...eventMetadata,
          ...commonTrackingParams,
          ...eventData
        }
      };

      // NOTE: THIS `console.log` call IS used for `functional tests`
      // hammerhead exists when testcafe runs
      // https://stackoverflow.com/a/55451665
      if (window && window['%hammerhead%']) {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify({
            type: 'tracking',
            data: {
              eventName,
              // don't include tracking params as they are generated randomly
              eventData: {
                ...eventMetadata,
                ...eventData
              }
            }
          })
        );
        // don't send the real tracking events if we're in the testcafe env
        return;
      }

      let tealiumData;

      trackyr.track(trackyrData.eventName, trackyrData.eventData);

      // google analytics
      let gaData;
      if (isGoogleAnalyticsLoaded()) {
        gaData = {
          eventName
        };
        trackGaEvent(gaData.eventName);
      } else {
        gaData = 'event skipped';
      }

      // tealium
      if (shouldTrackToTealium(eventName) && customerConfig?.tealium?.enabled) {
        tealiumData = {
          eventName,
          eventData: {
            ...externalTrackingParams,
            ...eventMetadata,
            ...commonTrackingParams,
            ...eventData
          }
        };
        trackTealiumEvent(tealiumData.eventName, tealiumData.eventData);
      } else {
        tealiumData = 'event skipped';
      }

      // captures an object that includes the metadata initialized in the `trackyr` object, for debugging the logs
      const muusData = {
        eventName: trackyrData.eventName,
        eventData: {
          ...trackyrInitMetadata,
          ...trackyrData.eventData
        }
      };

      if (Cookies.get('debug_analytics')) {
        // eslint-disable-next-line no-console
        console.log(
          JSON.stringify(
            {
              eventName,
              date: new Date().toISOString(),
              type: 'tracking',
              ga: gaData,
              tealium: tealiumData,
              muus: muusData
            },
            // make it look ~nice~
            null,
            '\t'
          )
        );
      }

      if (window.admin && window.admin?.isEditorActive()) {
        window._admin_logging = window._admin_logging || [];
        const trackingSummaryObject = {
          eventName,
          time: Date.now(),
          ga: gaData,
          tealium: tealiumData,
          muus: muusData
        };
        window._admin_logging.push(trackingSummaryObject);
        const evt = new CustomEvent('admin_tracking_debug_call', {
          detail: trackingSummaryObject
        });
        window.dispatchEvent(evt);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error tracking event', error);
    }
  };

  return { log };
};
