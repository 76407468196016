import isUaWebview from 'is-ua-webview';
import { isbot } from 'isbot';

import type { Request } from 'Common/types/express';

type UserAgentInfo = {
  isWebview: boolean;
  isBot: boolean;
};

/**
 * Returns info about the user-agent:
 * - isWebview
 * - isBot
 * Wraps the webview `isUaWebview()` and bot `isbot()` detectors,
 * unless the callback default params are overriden.
 * @param {object} req
 * @returns {UserAgentInfo} object containing isWebview and isBot, both holding boolean values
 */
export function getUserAgentInfo(req: Request): UserAgentInfo {
  return {
    isWebview: checkIfWebview(req),
    isBot: checkIfBot(req)
  };
}

export function checkIfWebview(req: Request): boolean {
  try {
    const ua = req.headers['user-agent'];
    if (!ua) {
      return false;
    }

    return isUaWebview(ua);
  } catch (e) {
    req
      .getLog()
      .debug(
        `failed to detect 'user-agent' as webview: ${(e as Error).message}`
      );
    return false;
  }
}

export function checkIfBot(req: Request): boolean {
  try {
    const appSettings = req.getAppSettings();
    const ua = req.headers['user-agent'];
    if (!ua) {
      return false;
    }
    if (appSettings.DEBUG && ua.includes('HeadlessChrome/')) {
      /** excluding HeadlessChrome as Jenkins runs testcafe with headless Chrome */
      return false;
    }

    return isbot(ua);
  } catch (e) {
    req
      .getLog()
      .debug(`failed to detect 'user-agent' as bot: ${(e as Error).message}`);
    return false;
  }
}
