import { validate as uuidValidate } from 'uuid';
import moment from 'moment';
import { isServerSide } from 'Common/utils';
import { SEARCH_ID_TOKEN_DELIMITER } from '../constants';
import {
  TIMESTAMP_QUERY_PARAM_NAME,
  TIMESTAMP_ALLOWED_FUTURE_MINUTES,
  TIMESTAMP_ALLOWED_PAST_MINUTES
} from './constants';

import type { Request } from 'Common/types/express';

// validate a search_id token
export function isSearchIdTokenValid(token: string): boolean {
  try {
    const [uuid, hash1, hash2] = token.split(SEARCH_ID_TOKEN_DELIMITER);
    // validate hash here? or is it enough to just validate the uuid?
    if (!isUuidValid(uuid)) {
      return false;
    }

    // if we don't have at least one hash, then it's invalid
    if (!isHashValid(hash1)) {
      return false;
    }

    // if we have a second hash, it needs to be valid
    if (hash2) {
      if (!isHashValid(hash2)) {
        return false;
      }
    }

    // if we're here, then the hash is valid
    return true;
  } catch (e) {
    return false;
  }
}

// generic uuid validation
export function isUuidValid(uuid: string): boolean {
  if (!uuid) {
    return false;
  }
  return uuidValidate(uuid);
}

export function isHashValid(hash: string): boolean {
  if (!hash) {
    return false;
  }
  return /\b([a-f0-9]{40})\b/.test(hash);
}

export function isTimestampValid({ req }: { req?: Request } = {}): boolean {
  let timestamp = null;

  if (isServerSide()) {
    if (req) {
      timestamp = req.query[TIMESTAMP_QUERY_PARAM_NAME];
    } else {
      throw new Error('validateTimestamp called without req on server side');
    }
  } else {
    const url = new URL(window.location.href);
    timestamp = url.searchParams.get(TIMESTAMP_QUERY_PARAM_NAME);
  }

  if (!timestamp) {
    // if no timestamp, then it's inherently invalid
    return false;
  }

  const timestampMoment = moment(timestamp as string, 'x');
  if (!timestampMoment.isValid()) {
    return false;
  }

  const now = moment();
  const earliestTime = now
    .clone()
    .subtract(TIMESTAMP_ALLOWED_PAST_MINUTES, 'm');
  const latestTime = now.clone().add(TIMESTAMP_ALLOWED_FUTURE_MINUTES, 'm');
  const isBetween = timestampMoment.isBetween(earliestTime, latestTime);
  return isBetween;
}
