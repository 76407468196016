// will get stubbed on the client side
import crypto from 'crypto';
import _pick from 'lodash/pick';

/**
 * When these fields change in the search, generate a new search id.
 * Changing filters does not constitute a new search id, only a new term or location
 */
const SEARCH_ID_WATCH_FIELDS = [
  // search widget params
  // unified, free text search
  'unified',
  'searchTerm', // gets sent when search widget is submitted without javascript
  'alias_term',
  // v9 typeahead categories, some overlap with v8
  'clinical_keywords',
  'conditions_symptoms',
  'marketable_locations',
  'marketable_location_type',
  'marketable_location_services',
  'network_affiliation',
  'practice_group',
  'primary_care',
  'provider_name',
  'specialties',
  'treatments_procedures',
  // v8 typeahead categories, some overlap with v9
  'name',
  'provider_name',
  'specialty',
  'specialty_strict',
  'specialty_synonym',
  'clinical_combined',
  'clinical_experience',
  'practice_group',
  'practice_location',
  'network_affiliation',
  'primary_care',
  'primary_care_condition',
  // location filter search params, same for v8 and v9
  'location',
  'location_name'
];

// get a sha-1 hash of the search, client side
/**
 * given a full search params object, return a sub-object of only the hashable fields,
 * which are the only fields we look at when deciding whether or not to create a new search id
 * @param {Object} searchParams
 */
export const getHashableObjectFromSearchParams = (searchParams) => {
  return _pick(searchParams, SEARCH_ID_WATCH_FIELDS);
};

export const hashSearchClient = async (searchParams) => {
  const message = JSON.stringify(
    getHashableObjectFromSearchParams(searchParams)
  );
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await window.crypto.subtle.digest('SHA-1', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashedString = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashedString;
};

// get a sha-1 hash of the search, server side
export const hashSearchServer = (searchParams) => {
  const message = JSON.stringify(
    getHashableObjectFromSearchParams(searchParams)
  );
  // Create a hash object
  const hash = crypto.createHash('sha1');
  // Update the hash with the string
  hash.update(message);
  // Digest the hash to get the hexadecimal representation
  const hashedString = hash.digest('hex');
  return hashedString;
};
