import Cookies from 'js-cookie';
import { isServerSide, getBaseCookieParams } from 'Common/utils';
import { isTimestampValid } from './validation';
import type { TokenDefinition } from './types';
import { METADATA_STORE_NAME } from './constants';
import type { Request } from 'Common/types/express';

const NEWLY_CREATED_TOKENS_TRACKING_KEY = '__new_token_values';

/**
 * ℹ️ Isomorphic functions for getting and setting tracking tokens, based
 * on their definitions in the `token-definitions` file.
 */

export function getValueFromQuery({
  req,
  token
}: {
  req?: Request;
  token: TokenDefinition;
}): string | null {
  const { queryParamName } = token;
  if (!queryParamName) {
    return null;
  }

  let queryValue: string | null = null;

  if (isServerSide()) {
    if (req) {
      queryValue =
        queryParamName in req.query
          ? (req.query[queryParamName] as string)
          : null;
    } else {
      throw new Error('getFromQuery called without req on server side');
    }
  } else {
    const url = new URL(window.location.href);
    queryValue = url.searchParams.get(queryParamName) || null;
  }

  if (!queryValue) {
    return null;
  }

  if (token.checkQueryTimestamp) {
    // if the token has a timestamp, validate it
    if (!isTimestampValid({ req })) {
      return null;
    }
  }

  return queryValue;
}

export function getValueFromCookie({
  req,
  token
}: {
  req?: Request;
  token: TokenDefinition;
}): string | null {
  const { cookieName } = token;
  if (!cookieName) {
    return null;
  }
  if (isServerSide()) {
    if (req) {
      return cookieName in req.cookies ? req.cookies[cookieName] : null;
    } else {
      throw new Error('getFromCookie called without req on server side');
    }
  } else {
    return Cookies.get(cookieName) || null;
  }
}

export function getValueFromMetadata({
  req,
  token
}: {
  req?: Request;
  token: TokenDefinition;
}): string | null {
  const { metadataField } = token;
  if (!metadataField) {
    return null;
  }

  if (isServerSide()) {
    if (req) {
      const metadata = req.loggingMetadata;
      if (metadata && metadataField in metadata) {
        return metadata[metadataField as keyof typeof metadata] as string;
      }
    } else {
      throw new Error('getFromMetadata called without req on server side');
    }
  } else {
    if (METADATA_STORE_NAME in window) {
      const metadataStore: any = (window as any)[METADATA_STORE_NAME];
      if (metadataStore && metadataField in metadataStore) {
        return metadataStore[metadataField];
      }
    }
  }

  return null;
}

function isTokenValid(token: TokenDefinition, value: string) {
  if (!value) {
    return false;
  }
  return token.validateFunc ? token.validateFunc(value) : true;
}

export function getTokenValue({
  req,
  token
}: {
  req?: Request;
  token: TokenDefinition;
}): string | null {
  /**
   * On server side, setting a cookie and then trying to read it in the same request
   * is complex. So if a cookie was generated during the request, we'll read it from
   * a special store in the request object which is populated by the `saveToken` function
   */
  if (token.queryParamName && isServerSide()) {
    if (req) {
      const newValueStore = req[NEWLY_CREATED_TOKENS_TRACKING_KEY];
      if (newValueStore && newValueStore[token.queryParamName]) {
        return newValueStore[token.queryParamName];
      }
    } else {
      throw new Error('getTokenValue called without req on server side');
    }
  }

  if (token.metadataField) {
    const metadataValue = getValueFromMetadata({ req, token });
    if (metadataValue && isTokenValid(token, metadataValue)) {
      return metadataValue;
    }
  }

  if (token.queryParamName) {
    const queryValue = getValueFromQuery({ req, token });
    if (queryValue && isTokenValid(token, queryValue)) {
      return queryValue;
    }
  }

  if (token.cookieName) {
    const cookieValue = getValueFromCookie({ req, token });
    if (cookieValue && isTokenValid(token, cookieValue)) {
      return cookieValue;
    }
  }

  if (token.generateByDefault) {
    if (token.generateFunc) {
      /**
       * If we had a missing or invalid token, and this token definition
       * tells us to generate a new random value, then generate a new one
       * and save it
       */
      const newValue = token.generateFunc({ req });
      // save the token so that subsequent reads get the same value
      saveToken({ req, token, value: newValue });
      return newValue;
    }
  }

  return null;
}

export function saveToken({
  req,
  token,
  value
}: {
  req?: Request;
  token: TokenDefinition;
  value: string | null;
}): void {
  const { cookieName, cookieExpiration } = token;

  if (!value) {
    // if no value, nothing to save
    return;
  }

  if (isServerSide()) {
    if (req) {
      /**
       * On server side, setting a cookie and then trying to read it in the same request
       * is somewhat complex. So, if a cookie was generated during the request, we'll store it in
       * a special store in the request object, so that any reading of the token during
       * the same request gets the same value
       */
      if (token.queryParamName) {
        if (!req[NEWLY_CREATED_TOKENS_TRACKING_KEY]) {
          req[NEWLY_CREATED_TOKENS_TRACKING_KEY] = {};
        }
        req[NEWLY_CREATED_TOKENS_TRACKING_KEY][token.queryParamName] = value;
      }
    } else {
      throw new Error('saveToken called without req on server side');
    }
  }

  if (!cookieName) {
    return;
  }

  if (!cookieExpiration) {
    throw new Error('cookieExpiration is required for token definitions');
  }

  const baseCookieParams = getBaseCookieParams();
  if (isServerSide()) {
    if (req && req.res) {
      // add a handler to save the cookie when the request is finished
      req?.addOnFinishedHandler(`save-token-${cookieName}`, () => {
        (req.res as any).cookie(cookieName, value, {
          ...baseCookieParams,
          // res expects maxAge in milliseconds
          maxAge: cookieExpiration * 60 * 1000
        } as any);
      });
    }
  } else {
    Cookies.set(cookieName, value, {
      ...baseCookieParams,
      // this library expects expiration to be in `days` and not `minutes`
      expires: Math.round(cookieExpiration / (60 * 24))
    });
  }
  return;
}
