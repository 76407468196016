import {
  // TODO: remove "_V8" actions when we fully transition to V9 search
  REQUEST_PROFILE_V8,
  RECEIVE_PROFILE_V8,
  RECEIVE_PROFILE_ERROR_V8,
  REQUEST_PROFILE,
  RECEIVE_PROFILE,
  RECEIVE_PROFILE_ERROR
} from '../profiles';
import {
  REQUEST_SEARCH,
  RECEIVE_SEARCH,
  RECEIVE_SEARCH_ERROR
} from '../search';

import { RECEIVE_SEARCH_V9 } from '../search-v9';

import {
  RECEIVE_MAP_LOCATIONS_V9,
  RECEIVE_MAP_PROVIDERS_V9
} from '../search-map-v9';

import {
  REQUEST_MAP_LOCATIONS,
  REQUEST_MAP_PROVIDERS,
  RECEIVE_MAP_LOCATIONS,
  RECEIVE_MAP_PROVIDERS,
  RECEIVE_MAP_LOCATIONS_ERROR,
  RECEIVE_MAP_PROVIDERS_ERROR
} from '../search-map';

import { CLEAR_DATA } from '../actions';

export const DEFAULT_STATE = true;

export default function loadingReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REQUEST_PROFILE_V8:
    case REQUEST_PROFILE:
    case REQUEST_SEARCH:
    case REQUEST_MAP_LOCATIONS:
    case REQUEST_MAP_PROVIDERS:
      return true;
    case RECEIVE_PROFILE_V8:
    case RECEIVE_PROFILE:
    case RECEIVE_SEARCH:
    case RECEIVE_SEARCH_V9:
    case RECEIVE_MAP_LOCATIONS:
    case RECEIVE_MAP_LOCATIONS_V9:
    case RECEIVE_MAP_PROVIDERS:
    case RECEIVE_MAP_PROVIDERS_V9:
      return false;
    case RECEIVE_PROFILE_ERROR_V8:
    case RECEIVE_PROFILE_ERROR:
    case RECEIVE_SEARCH_ERROR:
    case RECEIVE_MAP_LOCATIONS_ERROR:
    case RECEIVE_MAP_PROVIDERS_ERROR:
      return false;
    case CLEAR_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
}
