/** constants used for logging/Analytics **/

// becomes user_type on client events
// for agent and consumer user_type = persona
export const USER_TYPE_CONSUMER = 'Anonymous';

// becomes project_name on Search Service events
// for agent and consumer product_name = persona
export const PRODUCT_NAME_CONSUMER = 'pmc';

export const SEARCH_PURPOSES = {};

// delimiter between search id and the hashed search params in the token
export const SEARCH_ID_TOKEN_DELIMITER = '___';
