export const SET_TOKENS = 'SET_TOKENS';

/**
 * Tokens store for _persisted tokens_, which are not the same as the common tracking params we use
 * to track a user's session. These are 3rd party params that we want to persist between page views.
 * See persisted-token.ts for more details.
 */

import { type TokensStore } from './types';

export function setTokens(tokens: TokensStore) {
  return {
    type: SET_TOKENS,
    payload: tokens
  };
}
