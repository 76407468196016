import axios from 'axios';
import { getCommonTrackingParams } from 'Common/logging/tokens';
import { getMlocsForLocations } from './services';
import { getAltProviders, getCareTeamProviders } from '../providers/services';
import { getBaseUrlFromRequest } from '../../utils/url';
import getHttpsAgent from '../../utils/getHttpsAgent';
import getRequestHeaders from '../../utils/getRequestHeaders';

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V8 profile actions (we are deprecating this)
// ------------------------------------------------------------------
// ------------------------------------------------------------------

export const REQUEST_PROFILE_V8 = 'REQUEST_PROFILE_V8';
export const RECEIVE_PROFILE_V8 = 'RECEIVE_PROFILE_V8';
export const RECEIVE_PROFILE_ERROR_V8 = 'RECEIVE_PROFILE_ERROR_V8';

function requestProfileV8() {
  return {
    type: REQUEST_PROFILE_V8
  };
}

export function receiveProfileV8(responseData) {
  return {
    type: RECEIVE_PROFILE_V8,
    payload: responseData
  };
}

export function receiveProfileErrorV8() {
  return {
    type: RECEIVE_PROFILE_ERROR_V8
  };
}

/**
 * Fetch the v8 Provider Profile
 *
 * @param {object} location object
 * @param {object} req defined for SSR only
 */
export function fetchProfileV8({ pathname = '' } = {}, req) {
  return async (dispatch) => {
    dispatch(requestProfileV8());

    try {
      const baseUrl = await getBaseUrlFromRequest(req);
      const headers = await getRequestHeaders(req);

      const response = await axios.get(`${baseUrl}/api${pathname}`, {
        withCredentials: true,
        // allow local certs for development server side
        httpsAgent: getHttpsAgent(req),
        headers
      });

      if (response.status !== 200) {
        throw new Error('Invalid response from profile service');
      }

      const responseData = response.data.data;
      await dispatch(receiveProfileV8(responseData));
    } catch (e) {
      await dispatch(receiveProfileErrorV8());
    }
  };
}

// ------------------------------------------------------------------
// ------------------------------------------------------------------
// Search V9 profile actions
// ------------------------------------------------------------------
// ------------------------------------------------------------------

export const REQUEST_PROFILE = 'REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export const RECEIVE_PROFILE_ERROR = 'RECEIVE_PROFILE_ERROR';

function requestProfile() {
  return {
    type: REQUEST_PROFILE
  };
}

export function receiveProfile(responseData) {
  return {
    type: RECEIVE_PROFILE,
    payload: responseData
  };
}

export function receiveProfileError() {
  return {
    type: RECEIVE_PROFILE_ERROR
  };
}

export function fetchProfile({
  providerId,
  customerCode,
  config,
  searchSDK,
  req
} = {}) {
  return async (dispatch) => {
    dispatch(requestProfile());

    try {
      const searchClient = searchSDK.current;
      const params = {
        id: providerId,
        context: config.index || customerCode,
        ...getCommonTrackingParams({ req }),
        provider_fields: ['-clinical_keywords'].join(',')
      };
      const res = await searchClient.getProviderById(params);

      if (res.status !== 200) {
        throw new Error('Invalid response from profile service');
      }

      const profile = res.data;
      const { provider, locations } = profile;
      const [fetchedMlocs, fetchedAltProviders, careTeamProviders] =
        await Promise.all([
          getMlocsForLocations({
            locations,
            customerCode,
            req
          }),
          getAltProviders({
            provider,
            locations,
            customerCode,
            config,
            searchSDK,
            queryParams: {
              per_page: 2,
              page: 1,
              location_fields: ['-ALL', 'id', 'name'].join(','),
              provider_fields: [
                '-ALL',
                'id',
                'name',
                'image_url',
                'specialties',
                'book_online_url'
              ].join(',')
            },
            req
          }),
          getCareTeamProviders({
            provider,
            customerCode,
            config,
            searchSDK,
            req
          })
        ]);
      const profileData = {
        ...profile,
        mlocationsById: fetchedMlocs.reduce(
          (acc, mloc) => ({
            ...acc,
            [mloc._entity_id]: mloc
          }),
          {}
        ),
        altProviders: fetchedAltProviders.map(
          ({ availability, locations, provider }) => {
            return {
              location: locations[0],
              availability,
              provider,
              config
            };
          }
        ),
        careTeamProviders
      };
      await dispatch(receiveProfile(profileData));
    } catch (e) {
      if (req) {
        const log = req.getLog();

        log.error(`fetchProfile action failed: ${e.message}`);
      }

      await dispatch(receiveProfileError());
    }
  };
}
