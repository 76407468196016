import axios from 'axios';
import { Base64 } from 'js-base64';
import UAParser from 'ua-parser-js';

import {
  getTokenValue,
  CONSUMER_TRACKING_TOKEN,
  SEARCH_ID_TOKEN,
  SEARCH_TOKEN,
  USER_TOKEN
} from 'Common/logging/tokens';

import type { Request } from 'Common/types/express';

// code calling the logger can append any kind of additional arbitrary
// data and we'll send it along to the service
type AdditionalEventData = Record<string, unknown>;

type TrackingObject = {
  level: 'INFO' | 'ERROR' | 'DEBUG' | 'WARNING';
  message: string;
  eventName: string;
  error: Error | null;
  additionalEventData: AdditionalEventData;
};

type EventData = {
  customer_id: string;
  actor: string | null;
  page: string;
  search_token: string | null;
  user_token: string | null;
  user_type: string;
  search_id_token: string | null;
  url: string;
  method: string;
  remote_addr: string | number | null;
  referrer: string;
  user_agent: string;
  browser?: string;
  browser_version?: string;
  platform?: string;
} & AdditionalEventData;

type KloggyrRequest = {
  customer_code: string;
  deployment: string;
  event_data: EventData;
  event_name: string;
  event_time: string;
  level: 'INFO' | 'ERROR' | 'DEBUG' | 'WARNING';
  message: string;
  host: string;
  name: string;
  product_name: string;
  product_version: string;
  referrer: string;
  // tracking tokens
  tracking_token: string | null;
  user_id: string | null;
  user_metadata: Record<string, unknown>;
  kloggyr_version: string;
  utm_param: string;
  line_number?: number;
  file_name?: string;
  stack?: string;
};

const DEFAULT_DATA = {
  customer_code: '',
  deployment: '', // stg, prd, dev, etc
  event_data: {},
  event_name: '',
  event_time: '',
  level: 'INFO',
  message: '',
  host: '',
  name: 'providermatch_consumer',
  product_name: 'Providermatch Server',
  product_version: '',
  referrer: 'UNKNOWN',
  tracking_token: 'UNKNOWN',
  user_id: 'UNKNOWN',
  user_metadata: {},
  utm_param: 'UNKNOWN'
};

/**
 * Make a tracking request to the Kyruus "Kloggyr" service which creates log entries
 * that are later ingested and viewable via Kyruus "MUUS"
 * @param {Request} req the http request for context
 * @param {Object} details:
 *    level - error level ie INFO or WARNING or ERROR
 *    message - message to log to kloggyr. required
 *    eventName - optional event name to label in kloggyr. If not set, one will automatically be created for this event
 *    error - optional Error object that will be used to send additional data to kloggyr if available
 */
export async function sendToKloggyr(
  req: Request,
  {
    level = 'INFO',
    message,
    eventName = '',
    error = null,
    additionalEventData = {}
  }: TrackingObject
): Promise<boolean> {
  try {
    const appSettings = req.getAppSettings();
    const trackingObject = await buildTrackingObject(req, {
      level,
      message,
      eventName,
      error,
      additionalEventData
    });

    if (trackingObject && appSettings.KLOGGYR_URL) {
      const dataString = JSON.stringify(trackingObject);
      const requestUrl = `${
        appSettings.KLOGGYR_URL
      }api/log?data=${Base64.encodeURI(dataString)}`;
      // send tracking data. The response is binary image tracking pixel as this endpoint is primarily
      // used for client-side tracking, and we have no use for the response here
      await axios.get(requestUrl, {
        timeout: appSettings.TIMEOUT_KLOGGYR as number
      });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    // failed to send to kloggyr
    return false;
  }
}

export async function buildTrackingObject(
  req: Request,
  {
    level = 'INFO',
    message,
    eventName = '',
    error = null,
    additionalEventData = {}
  }: TrackingObject
): Promise<KloggyrRequest> {
  const appSettings = await req.getAppSettings();
  const appVersion = await req.getAppVersion();
  const actor = await req.getActor();

  const loggingMetadata = req.loggingMetadata;

  let customerCode = 'none';

  if (actor) {
    // if actor were null, getCustomerCode will always fail
    try {
      customerCode = await req.getCustomerCode();
    } catch (error) {
      // customerCode remains 'none'
    }
  }

  if (!eventName && error && error.name) {
    eventName = error.name;
  }
  if (!eventName) {
    // eventName is a required field
    eventName = 'missing event name';
  }

  let browser = 'unknown';
  let browser_version = 'unknown';
  let platform = 'unknown';

  if (req.headers['user-agent']) {
    try {
      const parser = new UAParser(req.headers['user-agent']);
      browser = parser.getBrowser().name || 'unknown';
      browser_version = parser.getBrowser().version || 'unknown';
      platform = parser.getOS().name || 'unknown';
    } catch (e) {
      // could not parse user agent :shrug:
    }
  }

  const fullData: KloggyrRequest = {
    ...DEFAULT_DATA,
    customer_code: customerCode,
    deployment: String(appSettings.KLOGGYR_DEPLOYMENT),
    event_name: eventName,
    event_data: {
      ...DEFAULT_DATA.event_data,
      customer_id: customerCode,
      actor: loggingMetadata.actor,
      page: req.path,
      search_token: getTokenValue({ req, token: SEARCH_TOKEN }),
      user_token: getTokenValue({ req, token: USER_TOKEN }),
      search_id_token: getTokenValue({ req, token: SEARCH_ID_TOKEN }),
      user_type: loggingMetadata.userType,
      url: req.url,
      method: req.method,
      remote_addr:
        (Array.isArray(req.headers['x-forwarded-for'])
          ? req.headers['x-forwarded-for'].join(' ')
          : req.headers['x-forwarded-for']) || 0,
      referrer:
        (Array.isArray(req.headers['referrer'])
          ? req.headers.referrer.join(' ')
          : req.headers.referer) || 'none',
      user_agent: req.headers['user-agent'] || 'unknown',
      browser,
      browser_version,
      platform,
      ...additionalEventData
    },
    message,
    level,
    event_time: new Date().toISOString(),
    product_version: appVersion,
    kloggyr_version: `pmc ${appVersion}`,
    referrer: req.headers.referer || 'none',
    tracking_token: getTokenValue({ req, token: CONSUMER_TRACKING_TOKEN }),
    user_id: loggingMetadata.userId
  };
  if (error) {
    if ((error as any).lineNumber) {
      fullData.line_number = (error as any).lineNumber;
    }
    if ((error as any).fileName) {
      fullData.file_name = (error as any).fileName;
    }
    if (error.stack) {
      fullData.stack = error.stack;
    }
  }
  return fullData;
}
