export function getInitialStateFromPage() {
  if (window.document.getElementById('state')) {
    try {
      return JSON.parse(
        window.document.getElementById('state').attributes['content'].value
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing state from page', e);
      return {};
    }
  }

  return {};
}
