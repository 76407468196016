import 'whatwg-fetch';
import './webpack-public-path';
//  import fetchIntercept from 'fetch-intercept';

import {
  theme as biomeTheme,
  ThemeProvider as BiomeThemeProvider,
  THEME_ID
} from '@biome-design-system/styles';
import { datadogRum } from '@datadog/browser-rum';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import * as FullStory from '@fullstory/browser';
import { Search } from '@kyruus/search-sdk';
import loadable, { loadableReady } from '@loadable/component';
import axios from 'axios';
import { func, object } from 'prop-types';
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { DynamicIntlProvider } from './intl/DynamicIntlProvider';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch, withRouter } from 'react-router-dom';

import kyruusTheme from '@kyruus/ui-theme';
import { shouldRenderDirectBookInDrawer } from 'Common/config';
import { getConfigProperty } from '../../common/config/utils';
import '../stylesheets/providermatch-consumer.scss';
import { setTestConfigs, setConfig } from './behaviors/configuration';
import { AuthenticatedUserInfoProvider } from './hooks/useAuthenticatedUserInfo';
import { loadPersistedTokensIntoStorage } from './tracking/persisted-tokens';
import { initializeLogging } from './tracking';
import {
  CrmConnectorProvider,
  ReferralConnectorProvider
} from './referral-crm-connector';
import configureStore from './store/configure-store';
import { getInitialStateFromPage } from './utils/state';
import { getLoginRedirectUrl } from './utils/url';

import { ThemeProvider } from '@emotion/react';
import { productNameSelector } from './behaviors/product-name';
import { clearAllFetchedData } from './behaviors/actions';
import CustomConfigActiveBanner from './editor/custom-config-active-banner';
import {
  AppointmentCancelRoute,
  DirectBookDrawerRoute,
  DirectBookRoute,
  LocationRoute,
  MapSearchRoute,
  ProfileRoute,
  RedirectRoute,
  SearchMatchRoute
} from './routes';
import LoadingOverlay from './shared/loading';

const APP_ID = 'pmc-app';
const EDITOR_APP_ID = 'pmc-editor-app';

const HomeContainer = loadable(() => import('./containers/home-container'));
const HomeContainerV9 = loadable(() =>
  import('./containers/home-container-v9')
);
const ProfileContainer = loadable(() =>
  import('./containers/profile-container')
);
// Lazily load DBW container with method to preload it when needed
const DBookingContainer = loadable(() =>
  import('./containers/direct-booking-container')
);
const AppointmentCancelContainer = loadable(() =>
  import('./containers/appointment-cancel-container')
);
const RedirectContainer = loadable(() =>
  import('./containers/redirect-container')
);
const LocationContainer = loadable(() =>
  import('./containers/location-container')
);
const MapSearchContainer = loadable(() =>
  import('./containers/map-search-container')
);
const MapSearchContainerV9 = loadable(() =>
  import('./containers/map-search-container-v9')
);
const PmcEditor = lazy(() => import('./editor/editor'));

const ClientApp = withRouter(
  ({
    log,
    config,
    customerCode,
    authenticatedUserInfo,
    location,
    productName
  }) => {
    const interceptRequestSuccess = (config) => {
      config.headers = {
        ...config.headers,
        // value doesn't matter, the header just needs to exist on the request
        'x-csrf-header': customerCode,
        'X-Requested-With': 'XMLHttpRequest'
      };
      return config;
    };
    const interceptError = (error) => {
      return Promise.reject(error);
    };
    // axios interceptors
    axios.interceptors.request.use(interceptRequestSuccess, interceptError);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          (error.status === 403 || error.response?.status === 403) &&
          (error.data?.loginServiceUrl || error.response?.data?.loginServiceUrl)
        ) {
          const loginServiceUrl =
            error.data?.loginServiceUrl ||
            error.response?.data?.loginServiceUrl;
          const loginRedirectUrl = getLoginRedirectUrl(
            window.location,
            loginServiceUrl
          );
          return window.location.replace(loginRedirectUrl);
        }
        return Promise.reject(error);
      }
    );
    // Disabling this feature for release 9.0.0 (KENG-40098) as it intereferes with a fetch request
    // to youtube in the provider-profile-component package and breaks provider videos on the profile
    // leaving code commented here for the followup fix to use
    //
    // fetch interceptors
    // fetchIntercept.register({
    //   request: function (url, config) {
    //     config = interceptRequestSuccess(config);
    //     return [url, config];
    //   },
    //   requestError: interceptError,
    //   response: async function (response) {
    //     if (response.status === 403) {
    //       const { loginServiceUrl } = await response.json();
    //       const loginRedirectUrl = getLoginRedirectUrl(
    //         window.location,
    //         loginServiceUrl
    //       );
    //       return window.location.replace(loginRedirectUrl);
    //     }
    //     return response;
    //   },
    //   responseError: interceptError
    // });

    const searchSDK = useRef(
      new Search({
        customerCode,
        productName,
        baseURL: '/api/searchservice-v9'
      })
    );

    const [caseId, setCaseId] = useState(null);

    // load tokens from/to localstorage. We need to do this early here because they're only
    // available on the client. These tokens are 'persisted' in that they should be appended
    // to cross-domain urls for tracking purposes, for example from search to DBW
    loadPersistedTokensIntoStorage();

    // If direct_book.render_type is 'drawer', preload the DBW container
    // to avoid flash of white content while it loads since
    // it is rendered within a drawer using react-router modal route
    // instead of standard route.
    // See https://v5.reactrouter.com/web/example/modal-gallery for more info.
    const renderDirectBookInDrawer = shouldRenderDirectBookInDrawer(config);
    useEffect(() => {
      if (renderDirectBookInDrawer) {
        DBookingContainer.preload();
      }
    }, [renderDirectBookInDrawer]);

    const memoLog = useCallback(
      (eventName, eventData) => {
        let data = eventData;

        if (caseId) {
          data = { ...data, case_id: caseId };
        }

        log(eventName, data);
      },
      [caseId, log]
    );

    // Provider used for modal routing when rendering DBW within drawer in agent mode
    const provider = location.state?.provider;

    return (
      <AuthenticatedUserInfoProvider userInfo={authenticatedUserInfo}>
        <CrmConnectorProvider
          config={config}
          log={memoLog}
          machineOptions={{
            actions: {
              onPatientReceived: setCaseId
            }
          }}
        >
          <ReferralConnectorProvider config={config} log={memoLog}>
            <Suspense fallback={<LoadingOverlay loading={true} />}>
              {/* If modal routing location exists, use it. otherwise, fallback to default location. */}
              <Switch location={provider?.location || location}>
                <ProfileRoute
                  config={config}
                  log={memoLog}
                  path={'/provider/:name/:id'}
                  component={ProfileContainer}
                  searchSDK={searchSDK}
                />
                {!provider && (
                  <DirectBookRoute
                    config={config}
                    log={memoLog}
                    path="/book/:providerId/:stepName?"
                    component={DBookingContainer}
                    searchSDK={searchSDK}
                  />
                )}
                <AppointmentCancelRoute
                  config={config}
                  log={memoLog}
                  path="/cancel/:appointmentId"
                  component={AppointmentCancelContainer}
                  searchSDK={searchSDK}
                />
                <RedirectRoute
                  config={config}
                  log={memoLog}
                  path="/redirect"
                  component={RedirectContainer}
                />
                <LocationRoute
                  config={config}
                  log={memoLog}
                  path="/location/:name/:id"
                  component={LocationContainer}
                />
                <MapSearchRoute
                  config={config}
                  log={memoLog}
                  path="/locations"
                  component={
                    config.darkship_use_list_page_searchv9
                      ? MapSearchContainerV9
                      : MapSearchContainer
                  }
                />
                <SearchMatchRoute
                  config={config}
                  log={memoLog}
                  path={'/'}
                  component={
                    config.darkship_use_list_page_searchv9
                      ? HomeContainerV9
                      : HomeContainer
                  }
                />
              </Switch>

              {/* Render the DBW modal route for use within a drawer. Uses the data provided in the location state in agent mode.
                Drawer always exists in the DOM in agent mode (to allow enter/exit animations to work properly) but is not accessible without
                the location state mentioned.
              */}
              {renderDirectBookInDrawer && (
                <DirectBookDrawerRoute
                  config={config}
                  log={memoLog}
                  path="/book/:providerId/:stepName?"
                  component={DBookingContainer}
                  searchSDK={searchSDK}
                  provider={provider?.provider}
                />
              )}
              <CustomConfigActiveBanner
                authenticatedUserInfo={authenticatedUserInfo}
              />
            </Suspense>
          </ReferralConnectorProvider>
        </CrmConnectorProvider>
      </AuthenticatedUserInfoProvider>
    );
  }
);

ClientApp.propTypes = {
  // logging function connected to `@kyruus/trackyr`
  log: func.isRequired,
  // config from store state
  config: object.isRequired
};

// DEATH TO JQUERY!
function ready(fn) {
  if (window.document.readyState != 'loading') {
    fn();
  } else {
    window.document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const initialState = getInitialStateFromPage();

  if (initialState.config?.datadog?.rum_enabled) {
    const ddRumConfig = {
      applicationId: '5841edf1-c54d-4ca4-958f-2c6d894bd1d0',
      clientToken: 'pubb895f16552dddfd9066608bded192aad',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'providermatch-consumer',
      env: initialState?.deployment?.environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: initialState?.deployment?.stackVersion,
      sessionSampleRate:
        initialState.config?.datadog?.rum_session_sample_rate ?? 100,
      sessionReplaySampleRate:
        initialState.config?.datadog?.rum_session_replay_sample_rate ?? 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [window.location.origin]
    };
    datadogRum.init(ddRumConfig);
  }

  const store = configureStore(initialState);

  const renderApp = () => {
    const currentState = store.getState();
    const {
      config,
      customerCode,
      authenticatedUserInfo,
      settings,
      loggingMetadata
    } = currentState;

    const productName = productNameSelector(currentState);

    const {
      locale = 'en-US',
      application_string_templates = {},
      theme = {}
    } = config;

    let themeToRenderDrawerInDirectBook = {};

    const { log } = initializeLogging({
      loggingMetadata,
      customerConfig: config
    });

    // If the feature flag is enabled, set the drawer screen sizes to a large value to prevent the drawer
    // from rendering desktop content
    if (shouldRenderDirectBookInDrawer(config)) {
      themeToRenderDrawerInDirectBook = {
        db_screen_large: '10000px',
        db_screen_medium: '10000px',
        db_screen_small: '10000px',
        db_screen_xsmall: '10000px'
      };
    }

    // NOTE: THIS `console.log` call IS used for `functional tests`
    // hammerhead exists when testcafe runs
    // https://stackoverflow.com/a/55451665
    if (window && window['%hammerhead%']) {
      window.__setTestConfig = (override) => {
        store.dispatch(setTestConfigs(override));
      };
    }

    window.addEventListener(
      'message',
      (event) => {
        // only accept events from the same origin, and window, not from other domains / iframe hosts, etc
        // Eventually if we hook this up from a different kyruus domain or PMA or somesuch, we'll want to
        // adjust this logic further
        if (
          event.origin === window.location.origin &&
          event.source === window
        ) {
          if (
            event.data?.eventName === 'pmc_editor:set_config' &&
            event.data?.eventData
          ) {
            // clear any fetched data so that it gets re-fetched with the new config
            store.dispatch(clearAllFetchedData());
            store.dispatch(setConfig(event.data.eventData));
            window.debug_rerender();
          }
        }
      },
      false
    );

    // Hydration of the ids in `data-emotion-css` will automatically occur when the cache is created
    const emotionCache = createCache({ key: 'css' });

    const customerBiomeTheme = {
      ...biomeTheme,
      ...theme,
      ...themeToRenderDrawerInDirectBook
    };
    const customerTheme = {
      ...kyruusTheme,
      ...theme,
      ...themeToRenderDrawerInDirectBook
    };

    ReactDOM.render(
      <ReduxProvider store={store}>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={customerTheme}>
            <BiomeThemeProvider theme={{ [THEME_ID]: customerBiomeTheme }}>
              <DynamicIntlProvider
                locale={locale}
                defaultLocale="en-US"
                messages={application_string_templates}
              >
                <BrowserRouter>
                  <ClientApp
                    dispatch={store.dispatch}
                    log={log}
                    config={config}
                    customerCode={customerCode}
                    productName={productName}
                    authenticatedUserInfo={authenticatedUserInfo}
                  />
                </BrowserRouter>
              </DynamicIntlProvider>
            </BiomeThemeProvider>
          </ThemeProvider>
        </CacheProvider>
      </ReduxProvider>,
      window.document.getElementById(APP_ID)
    );

    const fullstoryEnabled = getConfigProperty(
      config,
      'third_party.fullstory.enabled'
    );

    // init full story when enabled
    if (fullstoryEnabled === true && settings.FULLSTORY_ORG_ID != null) {
      FullStory.init({
        orgId: settings.FULLSTORY_ORG_ID,
        // add support for iframed environments
        recordOnlyThisIFrame: true
      });
    }
  };

  const renderEditorApp = () => {
    const isEditorEnabled = window.admin?.isEditorActive();

    if (!isEditorEnabled) {
      // if editor cookie is disabled, don't render it
      return;
    }
    let domElement = window.document.getElementById(EDITOR_APP_ID);
    if (domElement) {
      // if element already exists, exit here as we've already rendered it
      return;
    }

    const { config } = store.getState();

    const { locale = 'en-US', application_string_templates = {} } = config;

    // create the dom element for us to render the react tree into
    domElement = window.document.createElement('div');
    domElement.id = EDITOR_APP_ID;
    window.document.body.appendChild(domElement);

    ReactDOM.render(
      <CacheProvider value={createCache({ key: 'css' })}>
        <ThemeProvider theme={kyruusTheme}>
          <BiomeThemeProvider theme={{ [THEME_ID]: biomeTheme }}>
            <DynamicIntlProvider
              locale={locale}
              defaultLocale="en-US"
              messages={application_string_templates}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <PmcEditor />
              </Suspense>
            </DynamicIntlProvider>
          </BiomeThemeProvider>
        </ThemeProvider>
      </CacheProvider>,
      domElement
    );
  };

  loadableReady(() => {
    renderApp();
    renderEditorApp();
    // send event that we can hook into for testing
    window.pmc_ready = true;
    const readyEvt = new Event('pmc_ready');
    window.dispatchEvent(readyEvt);
  });

  // re-render the entire app client-side. for debug use only
  window.debug_rerender = () => {
    ReactDOM.unmountComponentAtNode(window.document.getElementById(APP_ID));
    // use existing store if a new one didnt get passed in
    renderApp();
  };

  // simple utils for speeding up dev by allowing bookmarklets to
  // access these features more easily and future-proof-ly
  window.admin = {
    enableEditor: () => {
      window.document.cookie =
        'debug_editor=true;path=/;secure;samesite=None;partitioned';
      renderEditorApp();
    },
    disableEditor: () => {
      window.document.cookie =
        'debug_editor=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;samesite=None;partitioned';
      window.admin.disableStringDebug();
      const domElem = window.document.getElementById(EDITOR_APP_ID);
      ReactDOM.unmountComponentAtNode(domElem);
      domElem.remove();
    },
    // check if the editor is current enabled, for use in callbacks and the such, not for rendering
    isEditorActive: () => {
      return window.document.cookie.indexOf('debug_editor=true') > -1;
    },
    enableStringDebug: () => {
      window.document.cookie = 'string_debug=true;path=/';
      window.debug_rerender();
    },
    disableStringDebug: () => {
      window.document.cookie =
        'string_debug=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
      window.debug_rerender();
    }
  };
});
