import { getTokenValue } from './interaction';
import {
  CONSUMER_TRACKING_TOKEN,
  USER_TOKEN,
  SEARCH_TOKEN,
  USER_ID
} from './definitions';

import { getExistingSearchData } from '../search-id';

import type { Request } from 'Common/types/express';
import type { CommonTrackingParams } from './types';

/**
 * Get object of tracking params as they are passed around to apis
 */
export function getCommonTrackingParams({
  req
}: {
  // required on server side
  req?: Request;
} = {}): CommonTrackingParams {
  // token is a multi-part token, so we need to extract only the id that we care about. searchId can be null
  const { searchId } = getExistingSearchData({ req });

  return {
    // search id is a composite token and we only need to send part of it
    search_id_token: searchId,
    user_token: getTokenValue({ req, token: USER_TOKEN }),
    tracking_token: getTokenValue({ req, token: CONSUMER_TRACKING_TOKEN }),
    search_token: getTokenValue({ req, token: SEARCH_TOKEN }),
    user_id: getTokenValue({ req, token: USER_ID })
  };
}
