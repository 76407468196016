import { getCommonTrackingParams } from 'Common/logging/tokens';

/**
 * Returns the alt providers array of objects based on provider and locations response
 * @param {object} provider - contains provider info
 * @param {array} locations - array of location objects
 * @param {string} customerCode - represents a customer
 * @param {object} config - default config of customer
 * @param {object} searchSDK - contains client and config info
 * @returns {Promise<array>} the alternate provider array of objects
 */

export const getAltProviders = async ({
  provider,
  locations,
  customerCode,
  config,
  searchSDK,
  queryParams,
  req
}) => {
  try {
    const searchClient = searchSDK.current;
    const params = {
      context: config.index || customerCode,

      ...getCommonTrackingParams({ req }),

      has_availability: true,
      slots_filter: {
        and: {
          and: {
            patient_relationship: ['new']
          }
        }
      },
      filter: {
        and: {
          and: {
            'provider.direct_book_capable': [true]
          }
        },
        not: {
          'provider.id': [provider.id]
        }
      },
      sort: 'relevance,distance,availability_density',
      exclude_from_analytics: true,

      ...queryParams
    };

    // add specialties OR filter if we have specialties
    // e.g. provider.specialties.name:Cardiology#Electrophysiology#Endocrinology
    if (provider.specialties && provider.specialties.length > 0) {
      params.filter.and.or = {
        'provider.specialties.name': provider.specialties.map(
          ({ name }) => name
        )
      };
    }

    // add location if we have one, just use the first one with a valid zip (may change with https://kyruus.jira.com/browse/KENG-36496)
    const firstLocationWithZip =
      locations && locations.length > 0
        ? locations.find((l) => Boolean(l.zip))
        : null;
    if (firstLocationWithZip) {
      params.location = firstLocationWithZip.zip;
    }

    const response = await searchClient.getProviders(params);

    return response.data._result;
  } catch (e) {
    if (req) {
      const log = req.getLog();
      log.error(`error fetching alternate providers: ${e}`);
    }
    return [];
  }
};
