import axios from 'axios';
import querystring from 'querystring';

import {
  getCommonTrackingParams,
  getTokenValue,
  SEARCH_SHUFFLE_TOKEN
} from 'Common/logging/tokens';

export const REQUEST_MAP_LOCATIONS = 'REQUEST_MAP_LOCATIONS';
export const RECEIVE_MAP_LOCATIONS = 'RECEIVE_MAP_LOCATIONS';
export const RECEIVE_MAP_LOCATIONS_ERROR = 'RECEIVE_MAP_LOCATIONS_ERROR';

export const REQUEST_MAP_PROVIDERS = 'REQUEST_MAP_PROVIDERS';
export const RECEIVE_MAP_PROVIDERS = 'RECEIVE_MAP_PROVIDERS';
export const RECEIVE_MAP_PROVIDERS_ERROR = 'RECEIVE_MAP_PROVIDERS_ERROR';

export const REQUEST_MAP_PROVIDERS_BY_LOCATION =
  'REQUEST_MAP_PROVIDERS_BY_LOCATION';
export const RECEIVE_MAP_PROVIDERS_BY_LOCATION =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION';
export const RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR =
  'RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR';

const PROVIDER_RESULTS_PER_PAGE = 10;

const MAX_BADGE_FIELDS = 3;

function getHeaders(state) {
  /**
   * @todo: in nodejs-land, customerCode is part of the redux state. When we move to SSR
   * through nodejs this will begin to fail and we'll need to update it to read from state instead of window
   */
  return {
    'X-Consumer-Groups': state.customerCode
  };
}

export function normalizeSearchParams(params) {
  const newParams = { ...params };
  if ('page' in newParams) {
    newParams.page = parseInt(newParams.page, 10) || 1;
  }
  if ('distance' in newParams) {
    newParams.distance = parseInt(newParams.distance, 10);
  }
  return newParams;
}

function getFacets(config) {
  return (config.facets || []).map((facet) => facet.field);
}

function requestMapLocations() {
  return {
    type: REQUEST_MAP_LOCATIONS
  };
}

export function receiveMapLocations(responseData) {
  return {
    type: RECEIVE_MAP_LOCATIONS,
    payload: responseData
  };
}

export function receiveMapLocationsError(error) {
  return {
    type: RECEIVE_MAP_LOCATIONS_ERROR,
    payload: error
  };
}

export function fetchMapLocations(params) {
  return async (dispatch, getState) => {
    dispatch(requestMapLocations());
    const fullParams = {
      // @todo: refine list of fields after we've build map features
      fields: ['ALL'].join(','),
      ...params
    };
    const state = getState();
    const { customerCode } = state;
    try {
      const response = await axios.post(
        `/api/searchservice-v9/${encodeURIComponent(customerCode)}/locations`,
        // send data in POST body:
        // location ids may be too many to be transmitted in a URL
        JSON.stringify(fullParams),
        {
          withCredentials: true,
          headers: getHeaders(getState())
        }
      );

      const data = response.data;

      dispatch(receiveMapLocations(data));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return data;
    } catch (error) {
      dispatch(receiveMapLocationsError(error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}

export function requestMapProviders() {
  return {
    type: REQUEST_MAP_PROVIDERS
  };
}

export function receiveMapProviders(responseData) {
  return {
    type: RECEIVE_MAP_PROVIDERS,
    payload: responseData
  };
}

export function receiveMapProvidersError(error) {
  return {
    type: RECEIVE_MAP_PROVIDERS_ERROR,
    payload: error
  };
}

/** Gets common params used in `fetchMapProviders*` functions. */
const getCommonMapProvidersParams = async ({ config, customerCode }) => ({
  source: config.index || customerCode,
  ...getCommonTrackingParams()
});

export function fetchMapProviders(params) {
  return async (dispatch, getState) => {
    const state = getState();
    const { config, customerCode } = state;
    dispatch(requestMapProviders());

    const commonMapProvidersParams = await getCommonMapProvidersParams({
      config,
      customerCode
    });

    const fullParams = {
      fields: [
        // we only need to read facets to get the list of locations to fetch subsequently,
        // so no fields are required at this time
        '-ALL'
      ].join(','),
      availability_format: 1,
      // shuffle_seed: Cookies.get('consumer_tracking_token'), // doesn't matter
      per_page: PROVIDER_RESULTS_PER_PAGE,
      // append id location ids facet to the facets from the config
      facet: ['locations.primary_marketable_location_id', ...getFacets(config)],
      ...commonMapProvidersParams,
      ...params
    };
    try {
      const response = await axios.get(
        `/api/searchservice-v8/${encodeURIComponent(
          customerCode
        )}/providers?${querystring.stringify(
          normalizeSearchParams(fullParams)
        )}`,
        {
          withCredentials: true,
          headers: getHeaders(getState())
        }
      );

      const data = response.data;

      dispatch(receiveMapProviders(data));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return data;
    } catch (error) {
      dispatch(receiveMapProvidersError(error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}

export function requestMapProvidersByLocation(locationId) {
  return {
    type: REQUEST_MAP_PROVIDERS_BY_LOCATION,
    payload: {
      locationId
    }
  };
}

export function receiveMapProvidersByLocation(locationId, responseData) {
  return {
    type: RECEIVE_MAP_PROVIDERS_BY_LOCATION,
    payload: {
      locationId,
      responseData
    }
  };
}

export function receiveMapProvidersByLocationError(locationId, error) {
  return {
    type: RECEIVE_MAP_PROVIDERS_BY_LOCATION_ERROR,
    payload: {
      locationId,
      error
    }
  };
}

export function getConfiguredSearchFields(config) {
  const { provider, logos, search_results_page } = config;
  let fields = [];

  // Collect "badge" fields from "config.provider.badge_data"
  if (provider && Array.isArray(provider.badge_data)) {
    fields = [
      ...fields,
      ...provider.badge_data
        .map((badge) => badge.field)
        .splice(0, MAX_BADGE_FIELDS)
    ];
  } else {
    // if badge fields are not in config, use "default" badge fields
    fields = [...fields, 'accepting_new_patients'];
  }

  // Collect "summary" fields from "config.search_results_page"
  if (search_results_page && Array.isArray(search_results_page.summary_data)) {
    fields = [
      ...fields,
      ...search_results_page.summary_data.map((fieldData) => fieldData.field)
    ];
  } else {
    // if summary_data is not in config, use "default" summary config fields
    fields = [...fields, 'specialties', 'hospital_affiliations'];
  }

  if (logos && Array.isArray(logos)) {
    // Collect "logos" fields from "config.logos"
    fields = [...fields, ...logos.map((logo) => logo.field)];
  }

  return fields;
}

/**
 * Filter out certain filters from the filters array that we don't want to include
 * in the call to get providers for a location
 * @param {Array} filters
 * @returns
 */
export function filterFilterParamsForProvidersByLocation(filters) {
  return (filters || []).filter((filter) => {
    const [name] = filter.split(':');
    // filter out `locations.name` filters because they contradict with `mloc id` filters
    // in the search
    if (name === 'locations.name') {
      return false;
    }
    return true;
  });
}

export function fetchMapProvidersByLocation(locationId, params) {
  return async (dispatch, getState) => {
    const state = getState();
    const { config, customerCode } = state;
    dispatch(requestMapProvidersByLocation(locationId));

    // create final array of filter params
    // if receive as array then spread the param values
    // if receive as string then wrap in [] to make array
    const manageFilter = (filter = []) => {
      return Array.isArray(filter) ? [...filter] : [filter];
    };

    const commonMapProvidersParams = await getCommonMapProvidersParams({
      config,
      customerCode
    });

    const fullParams = {
      fields: [
        '-ALL',
        'appointment_ehr_purposes',
        'availability_density_best',
        'book_online_url',
        'contacts',
        'degrees',
        'entity_type',
        'external_id',
        'gender',
        'id',
        'image_url',
        'locations',
        'name',
        'provider_type',
        'provider_videos',
        'request_appointment_url',
        'reviews',
        'virtual_care_url',
        ...getConfiguredSearchFields(config)
      ].join(','),
      availability_format: 1,
      shuffle_seed: getTokenValue({ token: SEARCH_SHUFFLE_TOKEN }),
      per_page: PROVIDER_RESULTS_PER_PAGE,
      // append id location ids facet to the facets from the config
      facet: ['id', ...getFacets(config)],
      ...commonMapProvidersParams,
      // typeahead_categories: '', // don't need to send this?
      // order matters. `params` needs to come before `filter` in this object
      // copied from the fields we fetch for providers on the list page in the v1 api
      ...params,
      filter: [
        ...filterFilterParamsForProvidersByLocation(
          manageFilter(params.filter)
        ),
        `locations.primary_marketable_location_id:${locationId}`
      ]
    };

    // append options `components`
    if (config?.search_params?.geocoding?.components?.administrative_area) {
      fullParams.components = `administrative_area:${config.search_params.geocoding.components.administrative_area}`;
    }

    try {
      const response = await axios.get(
        `/api/searchservice-v8/${encodeURIComponent(
          customerCode
        )}/providers?${querystring.stringify(
          normalizeSearchParams(fullParams)
        )}`,
        {
          withCredentials: true,
          headers: getHeaders(getState())
        }
      );

      const data = response.data;

      dispatch(receiveMapProvidersByLocation(locationId, data));
      // return the response data for any service (xstate) that might
      // be fetching data through redux
      return data;
    } catch (error) {
      dispatch(receiveMapProvidersByLocationError(locationId, error));
      // return null if something goes wrong, up to any service (xstate)
      // fetching data through redux to handle this
      return null;
    }
  };
}
