import {
  RECEIVE_SEARCH,
  RECEIVE_SEARCH_ERROR,
  TOGGLE_MOBILE_FACETS
} from './actions';

import { CLEAR_DATA } from '../actions';

export const DEFAULT_STATE = {
  providerIds: [],
  totalProviders: null,
  totalPages: null,
  facets: [],
  searchSummary: {},
  sortOptions: []
};

function getProviderIds(providers = []) {
  return providers.map((provider) => provider.id || provider.profile.id);
}

function getSearchPageState(payload) {
  const providerIds = getProviderIds(payload.providers);
  const nextState = { ...payload };
  delete nextState.providers;
  nextState.providerIds = providerIds;
  return nextState;
}

export default function searchReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case RECEIVE_SEARCH:
      return Object.assign({}, state, getSearchPageState(action.payload));
    case RECEIVE_SEARCH_ERROR:
      return Object.assign({}, state, DEFAULT_STATE);
    case TOGGLE_MOBILE_FACETS:
      return Object.assign({}, state, {
        showMobileFacets: !state.showMobileFacets
      });
    case CLEAR_DATA:
      return Object.assign({}, DEFAULT_STATE);
    default:
      return state;
  }
}
