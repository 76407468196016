import { SET_TEST_CONFIGS, SET_CONFIG } from './actions';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

export default function configurationReducer(state = {}, action) {
  switch (action.type) {
    case SET_TEST_CONFIGS: {
      const newState = cloneDeep(state);
      return merge(newState, action.payload);
    }
    case SET_CONFIG: {
      return cloneDeep(action.payload);
    }
    default:
      return state;
  }
}
