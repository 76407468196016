import { RECEIVE_SEARCH, UPDATE_PROVIDER_SLOT_COUNTS } from '../search';
import { RECEIVE_SEARCH_V9 } from '../search-v9';

import { CLEAR_DATA } from '../actions';

function getProviderId(provider) {
  return provider.id;
}

function getProviderObject(provider) {
  const providerId = getProviderId(provider);
  return { [providerId]: provider };
}

function getProviderMap(providers) {
  const providerMap = providers.reduce((providerAcc, provider) => {
    return Object.assign(providerAcc, getProviderObject(provider));
  }, {});
  return providerMap;
}

export default function providerReducer(state = {}, action) {
  switch (action.type) {
    case RECEIVE_SEARCH:
    case UPDATE_PROVIDER_SLOT_COUNTS:
      return Object.assign({}, state, getProviderMap(action.payload.providers));
    case RECEIVE_SEARCH_V9:
      return Object.assign(
        {},
        state,
        getProviderMap(
          action.payload.providers.map(
            ({ provider, locations, sort, availability }) => {
              return {
                ...provider,
                locations,
                sort,
                // custom key appended to provider object response
                has_provider_availability: !!availability?.slots?.length
              };
            }
          )
        )
      );
    case CLEAR_DATA:
      return {};
    default:
      return state;
  }
}
