import { SEARCH_ID_TOKEN, generateUuid } from '../tokens/definitions';
import { mergeSearchParamsAndNlpResult } from '../../search/nlp';
import { isServerSide } from 'Common/utils/serverSide';
import { hashSearchServer, hashSearchClient } from './search-id-hashing';
import { getTokenValue, saveToken } from 'Common/logging/tokens/interaction';
import { SEARCH_ID_TOKEN_DELIMITER } from '../constants';

/**
 * get hashed value from the search params object, and search results if we have them.
 * It's important to include the search results because the search params can be transformed by the
 * NLP fields in the results
 */
export const getHashedSearch = async (searchParams, searchResults = null) => {
  const nlpData = searchResults?.messages?.nlp_actions;
  const transformedSearchParams = mergeSearchParamsAndNlpResult(
    searchParams,
    nlpData
  );
  const hashedSearch = await hashSearch(transformedSearchParams);
  return hashedSearch;
};

/**
 * get stored data for existing search id and the associated hashed search
 * @param {*} param0
 * @returns
 */
export const getExistingSearchData = ({ req } = {}) => {
  const searchIdTokenValue = getTokenValue({ req, token: SEARCH_ID_TOKEN });

  if (!searchIdTokenValue) {
    return {
      searchId: null,
      searchParamsHash: null,
      transformedSearchParamsHash: null
    };
  }
  const [searchId, searchParamsHash, transformedSearchParamsHash] = (
    searchIdTokenValue || ''
  ).split(SEARCH_ID_TOKEN_DELIMITER);
  return { searchId, searchParamsHash, transformedSearchParamsHash };
};

/**
 * save the given searchId and hashedParams to the search id cookie
 * hashedTransformedParams is the hash of params after transformation by NLP
 * @param {*} param0
 */
export const saveSearchToken = ({
  searchId,
  hashedParams,
  hashedTransformedParams,
  req
}) => {
  let value = `${searchId}${SEARCH_ID_TOKEN_DELIMITER}${hashedParams}`;

  if (hashedTransformedParams) {
    value += `${SEARCH_ID_TOKEN_DELIMITER}${hashedTransformedParams}`;
  }

  return saveToken({
    req,
    token: SEARCH_ID_TOKEN,
    value
  });
};

/**
 * Save current data of the search to compare against in subsequent searches.
 * Store it in LS to persist across pages and uncouple from general search handling
 *
 * This function is to be called before the search is actually executed, so that we can
 * create a new search id and send it along with the search if we need to
 * @param {} searchSummary
 */
export const handleSearchIdTracking = async ({ searchParams, req }) => {
  // get existing search data
  const {
    searchParamsHash: previousSearchParamsHash,
    transformedSearchParamsHash: previousTransformedSearchParamsHash
  } = getExistingSearchData({
    req
  });

  const newSearchParamsHash = await getHashedSearch(searchParams);

  // if the search params have changed, we need a new search id
  if (
    newSearchParamsHash === previousSearchParamsHash ||
    newSearchParamsHash === previousTransformedSearchParamsHash
  ) {
    // if new search params hash is the same as the previous search params hash,
    // we don't need to generate a new id so can exit here.
    // Also consider the search the same if the previous transformed hash is the same
    // as the current search.
    return;
  }

  const newSearchId = generateUuid();

  // this block is only for debug messages on the server
  if (req) {
    const appSettings = req.getAppSettings();
    if (appSettings.DEBUG) {
      req
        .getLog()
        .debug(
          `A new search id will be generated for this search: ${newSearchId}`,
          searchParams
        );
    }
  }

  /**
   * Save data and token for this new search, which is unique from the previous search
   * based on the fields that we are considering a new search
   */
  saveSearchToken({
    searchId: newSearchId,
    hashedParams: newSearchParamsHash,
    req
  });
};

/**
 * need to track the search results that we got back for a search id,
 * and incorporate any NLP data that could have transformed the original params,
 * so that subsequent searches can be compared against this data
 * @param {} param0
 */
export const saveSearchIdResultsData = async ({
  req,
  searchParams,
  searchResults
}) => {
  const hashedTransformedSearch = await getHashedSearch(
    searchParams,
    searchResults
  );

  // existing stored search id. Since this function is called right after the search is kicked off,
  // we can assume that this function is being called in the same context as the 'current' search.
  // meaning that searchParamsHash is the hash of the search params that were sent to the server
  // to receive `searchResults`
  const { searchId, searchParamsHash } = getExistingSearchData({
    req
  });

  if (hashedTransformedSearch !== searchParamsHash) {
    // if the transformed hash is different from the original hash, that means that a
    // transformation of the search occurred. In this case we want to record both
    // hashes for subsequent searches, with the same search ID.
    saveSearchToken({
      searchId,
      hashedParams: searchParamsHash,
      hashedTransformedParams: hashedTransformedSearch,
      req
    });
  }

  // if we're here, that means the search wasn't transformed and that we don't need
  // to update the search id token with an additional hash for a transformed search
};

export const hashSearch = async (searchParams) => {
  // yes, the server side implementation is sync, while client is async
  if (isServerSide()) {
    return hashSearchServer(searchParams);
  }
  return await hashSearchClient(searchParams);
};
