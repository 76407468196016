import { LOADING_SLOT_COUNTS_START, LOADING_SLOT_COUNTS_END } from '../search';

import { CLEAR_DATA } from '../actions';

export const DEFAULT_STATE = false;

export default function slotsCountLoadingReducer(
  state = DEFAULT_STATE,
  action
) {
  switch (action.type) {
    case LOADING_SLOT_COUNTS_START:
      return true;
    case LOADING_SLOT_COUNTS_END:
      return false;
    case CLEAR_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
}
